import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H4, SmallText } from 'styles/typography'

import { SPACING } from '../../styles/tokens'

export const StyledTabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    position: absolute;
    width: calc(100% - 100px);
    margin: -${SPACING.L} -${SPACING.L} ${SPACING.L} -${SPACING.M};
`

export const StyledTabContainer = styled.div`
    display: flex;
    flex-direction: row;
`

export const StyledTab = styled.div<{ selected: boolean }>`
    margin: 0 20px;
    padding-bottom: 16px;
    cursor: pointer;
    display: flex;
    &:first-child {
        margin-left: 50px;
    }
    ${(props) => props.selected && `border-bottom: 3px solid ${colors.brown}`}
`

export const StyledTabIcon = styled.div<{ selected: boolean }>`
    margin-top: 5px;
    path {
        fill: ${(props) => (props.selected ? colors.brown : colors.defaultGrey)};
    }
`

export const StyledTabNumber = styled(SmallText)<{ selected: boolean }>`
    margin-top: 3px;
    margin-left: 8px;
    background: ${(props) => (props.selected ? colors.brown : colors.beige)};
    color: ${(props) => (props.selected ? colors.white : colors.defaultGrey)};
    border-radius: 10px;
    padding: 0 8px;
    height: fit-content;
`

export const StyledH4 = styled(H4)<{ selected: boolean }>`
    margin: 0;
    margin-left: 15px;
    font-family: ${fonts.flameSans};
    color: ${(props) => (props.selected ? colors.brown : colors.defaultGrey)};
`

export const StyledContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 180px 0 0;
`
