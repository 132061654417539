import { Button } from 'components/button/Button'
import { Dropdown } from 'components/dropdown/Dropdown'
import { Input } from 'components/inputs'
import { Notif } from 'components/notif/Notif'
import { ShouldDisplay } from 'components/should-display/ShouldDisplay'
import { Spacer } from 'components/spacer'
import { useCurrentComplaint } from 'hooks/useCurrentComplaint'
import { useAppDispatch } from 'hooks/useRedux'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { updateComplaint } from 'redux/complaint/complaintSlice'
import { closeComplaint } from 'redux/complaint/thunks'
import { colors } from 'styles/colors'
import { H2, Text } from 'styles/typography'
import { ComplaintMessageDTO, StoreLocRestaurant } from 'types/api'
import { ComplaintClosureReason, ComplaintStatus } from 'types/api.enum'
import { DropDownItem } from 'types/dropdown-item'
import { NotyfType, SenderType } from 'utils/enums'
import { cleanRestaurantName, formatDate, formatPascalCase } from 'utils/util'

import {
    StyledCombinedTextField,
    StyledModalBody,
    StyledModalContainer,
    StyledModalFooter,
    StyledModalHeader,
} from './style'

interface CloseComplaintModalContentProps {
    onClose: () => void
    currentRestaurant: StoreLocRestaurant
}

export const CloseComplaintModalContent = ({ onClose, currentRestaurant }: CloseComplaintModalContentProps) => {
    const { t } = useTranslation()
    const complaint = useCurrentComplaint()
    const {
        id: complaintId,
        createdDate: complaintCreationDate = '',
        clientFirstName = '',
        clientLastName = '',
    } = complaint
    const [pending, setPending] = useState(false)
    const dispatch = useAppDispatch()

    const complaintClosureReasonsList = useMemo(
        () =>
            Object.values(ComplaintClosureReason).map((reason) => ({
                value: reason,
                label: t(`complaints.closureReasons.${reason}`),
            })) as DropDownItem[],
        [t],
    )

    const initialEditableMessage = t('complaints.closeModal.message.editablePart')
    const [closureReason, setClosureReason] = useState<ComplaintClosureReason | undefined>()
    const [otherReason, setOtherReason] = useState<string>()
    const [editableMessage, setEditableMessage] = useState(initialEditableMessage)

    const handleReasonSelected = useCallback((e: DropDownItem) => {
        setClosureReason(e.value)
    }, [])

    const handleMessageEntered = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setEditableMessage(e.target.value)
    }, [])

    const handleOtherReasonChanged = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOtherReason(e.target.value)
    }, [])

    const partOneMessage = t(
        `complaints.closeModal.message.${closureReason === ComplaintClosureReason.OTHER ? 'partOneForOther' : 'partOne'}`,
        {
            clientFullName: `${clientFirstName} ${clientLastName}`.trim(),
            complaintCreationDate: formatDate(new Date(complaintCreationDate)),
            restaurantName: cleanRestaurantName(currentRestaurant.name),
        },
    )

    const [partTwoMessage, setPartTwoMessage] = useState<string>(
        t(`complaints.closeModal.message.partTwo.${closureReason ? closureReason : ComplaintClosureReason.ABUSIVE}`),
    )
    const [partThreeMessage, setPartThreeMessage] = useState<string>(
        t(`complaints.closeModal.message.partThree.${closureReason ? closureReason : ComplaintClosureReason.ABUSIVE}`, {
            restaurantName: formatPascalCase(currentRestaurant.name) ?? '',
        }),
    )

    useEffect(() => {
        setPartTwoMessage(
            t(
                `complaints.closeModal.message.partTwo.${closureReason ? closureReason : ComplaintClosureReason.ABUSIVE}`,
            ),
        )
        setPartThreeMessage(
            t(
                `complaints.closeModal.message.partThree.${closureReason ? closureReason : ComplaintClosureReason.ABUSIVE}`,
                {
                    restaurantName: formatPascalCase(currentRestaurant.name) ?? '',
                },
            ),
        )
    }, [closureReason, currentRestaurant.name, t])

    const isFormValid =
        !!closureReason &&
        !!editableMessage &&
        (closureReason !== ComplaintClosureReason.OTHER ||
            (closureReason === ComplaintClosureReason.OTHER && !!otherReason))
    const isValidateButtonDisabled = !isFormValid || pending

    const handleValidate = useCallback(async () => {
        if (!isFormValid) {
            return
        }
        setPending(true)
        try {
            const isInitialValue = editableMessage.trim() === initialEditableMessage.trim()

            const fullMessage = `${partOneMessage} ${closureReason === ComplaintClosureReason.OTHER ? '' : `\n\n${partTwoMessage}`} ${isInitialValue ? '' : `\n\n${editableMessage}`} \n\n${partThreeMessage}`

            await dispatch(
                closeComplaint({
                    id: complaintId ?? '',
                    closureReason,
                    otherReason,
                    restaurantMessage: isInitialValue ? '' : editableMessage,
                    message: fullMessage,
                }),
            ).unwrap()

            Notif({
                type: NotyfType.SUCCESS,
                text: t('complaints.closeModal.successNotif'),
            })

            const newMessage: ComplaintMessageDTO = {
                date: new Date().toLocaleDateString(),
                message: fullMessage,
                sender: SenderType.RESTAURANT,
            }
            const newMessages =
                complaint.messages && complaint.messages.length > 0 ? [...complaint.messages, newMessage] : [newMessage]
            const newComplaint = {
                complaintNumber: complaint.complaintNumber || 0,
                createdDate: complaint.createdDate || '',
                endDate: complaint.endDate || undefined,
                id: complaint.id,
                inCharge: complaint.inCharge,
                price: complaint.price || 0,
                status: ComplaintStatus.CLOSED,
                messages: newMessages,
                refundType: undefined,
            }
            dispatch(updateComplaint(newComplaint))
        } catch (_error) {
            Notif({ type: NotyfType.ERROR, text: t('error.closeComplaint') })
        } finally {
            onClose()
            setPending(false)
        }
    }, [
        closureReason,
        complaint.complaintNumber,
        complaint.createdDate,
        complaint.endDate,
        complaint.id,
        complaint.inCharge,
        complaint.messages,
        complaint.price,
        complaintId,
        dispatch,
        editableMessage,
        initialEditableMessage,
        isFormValid,
        onClose,
        otherReason,
        partOneMessage,
        partTwoMessage,
        partThreeMessage,
        t,
    ])

    const messageParts = [
        {
            message: partOneMessage,
            editable: false,
        },
        {
            message: partTwoMessage,
            editable: false,
        },
        {
            message: editableMessage,
            editable: true,
            handleChange: handleMessageEntered,
        },
        {
            message: partThreeMessage,
            editable: false,
        },
    ]

    if (closureReason === ComplaintClosureReason.ALREADY_PROCESSED) {
        messageParts.splice(2, 1)
    }

    if (closureReason === ComplaintClosureReason.OTHER) {
        messageParts.splice(1, 1)
    }

    return (
        <StyledModalContainer>
            <StyledModalHeader>
                <H2 color={colors.brown}>{t('complaints.closeModal.title')}</H2>
            </StyledModalHeader>
            <StyledModalBody>
                <Text>{t('complaints.closeModal.desc')}</Text>
                <Spacer />
                <Dropdown
                    list={complaintClosureReasonsList}
                    placeholder={t('complaints.closeModal.reasonPlaceholder')}
                    onChange={handleReasonSelected}
                    dialogVariant
                    isInsideModal
                />
                <ShouldDisplay condition={closureReason === ComplaintClosureReason.OTHER}>
                    <Spacer />
                    <Input
                        placeholder={t('complaints.closeModal.otherReasonPlaceholder')}
                        value={otherReason}
                        onChange={handleOtherReasonChanged}
                        type="search"
                    />
                </ShouldDisplay>

                <StyledCombinedTextField parts={messageParts} />
            </StyledModalBody>
            <StyledModalFooter>
                <Button text={t('component.button.cancel')} color={colors.brown} outlined onClick={onClose} />
                <Button
                    text={t('component.button.validate')}
                    color={colors.red}
                    onClick={handleValidate}
                    disabled={isValidateButtonDisabled}
                />
            </StyledModalFooter>
        </StyledModalContainer>
    )
}
