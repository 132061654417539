import { endpoints } from 'httpServices/endpoints'
import {
    BirthdayBookingDetailsDTO,
    ChatIdForm,
    ComplaintContestForm,
    ComplaintDTO,
    ComplaintFranchisedRestaurantCloseRequest,
    ComplaintFranchisedRestaurantRefundRequest,
    ComplaintFranchisedRestaurantReportRequest,
    ComplaintLightDTO,
    ComplaintPromotionDTO,
    CrmEmployeeDTO,
    CrmEmployeeForm,
    EmbeddedTokenResponse,
    EmployeeWithCouponDTO,
    FetchOrdersResponse,
    InitializeResponseDTO,
    LoginTabForm,
    OrderDTO,
    PageBirthdayBookingListItemDTO,
    ParameterPermissionForm,
    ParameterProfileInfo,
    PatchComplaintForm,
    PickupInfoForm,
    PreparationTimeForm,
    QrCodeDownloadRequest,
    RestaurantDTO,
    RestaurantInfo,
    RestaurantPermissionDTO,
    RestaurantServiceForm,
    ServiceDTO,
    ServiceOpeningsForm,
    SwapParkingTimeForm,
    SwapParkingToDriveForm,
    TabUserResetPasswordForm,
} from 'types/api'
import { DateRange } from 'types/date-range'

import { client } from './client'

export const services = {
    app: {
        init: (): Promise<{ data: InitializeResponseDTO }> => client.get(endpoints.app.init),
    },
    birthdays: {
        fetchList: (frNumber: string, page?: number): Promise<{ data: PageBirthdayBookingListItemDTO }> =>
            client.get(endpoints.birthdays.list, {
                params: {
                    frNumber,
                    page,
                },
            }),
        fetch: (frNumber: string, id: string): Promise<{ data: BirthdayBookingDetailsDTO }> =>
            client.get(endpoints.birthdays.birthday + id, {
                params: {
                    frNumber,
                },
            }),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        updateBirthday: (frNumber: string, id: string, data: any): Promise<{ data: BirthdayBookingDetailsDTO }> => {
            return client.patch(endpoints.birthdays.birthday + id, data, {
                params: {
                    frNumber,
                },
            })
        },
    },
    complaint: {
        fetch: (complaintId: string): Promise<{ data: { complaint: ComplaintDTO } }> =>
            client.get(endpoints.complaint.fetch, {
                params: { complaintId },
            }),
        fetchList: (frNumber: string, dateRange?: DateRange): Promise<{ data: { complaints: ComplaintLightDTO[] } }> =>
            client.get(endpoints.complaint.list, {
                params: {
                    frNumber,
                    ...(dateRange?.startDate && { startDate: dateRange.startDate.toDateString() }),
                    ...(dateRange?.endDate && { endDate: dateRange.endDate.toDateString() }),
                },
            }),
        fetchRefundList: (complaintId: string): Promise<{ data: { promotions: ComplaintPromotionDTO[] } }> =>
            client.get(endpoints.complaint.refundList(complaintId)),
        validate: (complaintId: string): Promise<{ data: object }> =>
            client.post(endpoints.complaint.validate, {
                complaintId: complaintId,
            }),
        contest: (form: ComplaintContestForm): Promise<{ data: object }> =>
            client.post(endpoints.complaint.contest, form),
        setChatId: (form: ChatIdForm): Promise<{ data: object }> => client.post(endpoints.complaint.chatId, form),
        validateRefund: (complaintId: string): Promise<{ data: object }> =>
            client.post(endpoints.complaint.validateRefund, {
                complaintId: complaintId,
            }),
        patch: (complaintId: string, form: PatchComplaintForm) =>
            client.patch(endpoints.complaint.patch(complaintId), form),
        close: (complaintId: string, form: ComplaintFranchisedRestaurantCloseRequest) =>
            client.post<{ complaint: ComplaintDTO }>(endpoints.complaint.close(complaintId), form),
        report: (complaintId: string, form: ComplaintFranchisedRestaurantReportRequest) =>
            client.post<{ complaint: ComplaintDTO }>(endpoints.complaint.report(complaintId), form),
        answer: (complaintId: string, message: string) =>
            client.post(endpoints.complaint.answer(complaintId), {
                message,
            }),
        refund: (complaintId: string, refund: ComplaintFranchisedRestaurantRefundRequest) =>
            client.post(endpoints.complaint.refund(complaintId), {
                ...refund,
            }),
    },
    user: {
        login: (form: LoginTabForm): Promise<{ headers: { authorization: string } }> =>
            client.post(endpoints.user.login, form),
        setPassword: (form: TabUserResetPasswordForm, token: string): Promise<{ data: object }> =>
            client.post(endpoints.user.setPassword, form, {
                headers: {
                    Authorization: token,
                },
            }),
        getPermissions: (): Promise<{ data: { restaurants: RestaurantPermissionDTO[] } }> =>
            client.get(endpoints.user.permissions),
        forgottenPassword: (form: { email: string }): Promise<{ data: object }> =>
            client.post(endpoints.user.forgottenPassword, form),
    },
    restaurant: {
        fetch: (frNumber: string): Promise<{ data: RestaurantInfo }> =>
            client.get(endpoints.restaurant.fetch, {
                params: { frNumber },
            }),
        setPreparationTime: (form: PreparationTimeForm): Promise<{ data: object; status: number }> =>
            client.post(endpoints.restaurant.preparationTime, form),
        toggleSwap: (form: SwapParkingToDriveForm): Promise<{ data: { restaurant: RestaurantDTO } }> =>
            client.post(endpoints.restaurant.swapDriveParking, form),
        swapDriveParkingTime: (form: SwapParkingTimeForm): Promise<{ data: { restaurant: RestaurantDTO } }> =>
            client.post(endpoints.restaurant.swapParkingTime, form),
        toggleService: (form: RestaurantServiceForm): Promise<{ data: { service: ServiceDTO } }> =>
            client.post(endpoints.restaurant.services, form),
        changePickupInfo: (form: PickupInfoForm): Promise<{ data: RestaurantInfo }> =>
            client.post(endpoints.restaurant.setPickupInfo, form),
        getServiceSchedule: (form: RestaurantServiceForm): Promise<{ data: ServiceOpeningsForm }> =>
            client.get(endpoints.restaurant.serviceSchedule, { params: form }),
        postServiceSchedule: (schedule: ServiceOpeningsForm): Promise<{ data: ServiceOpeningsForm; status: number }> =>
            client.post(endpoints.restaurant.serviceSchedule, schedule),
        powerBi: {
            getToken: async (frNumber: string): Promise<EmbeddedTokenResponse> => {
                const response = await client.get(endpoints.restaurant.powerBi(frNumber))
                return response.data
            },
        },
    },
    order: {
        fetch: (orderId: string): Promise<{ data: { order: OrderDTO } }> =>
            client.get(endpoints.order.fetch, {
                params: { orderId },
            }),
        fetchPendingOrderList: (frNumber: string): Promise<{ data: FetchOrdersResponse }> =>
            client.get(endpoints.order.list.pending, {
                params: {
                    frNumber,
                },
            }),
        fetchFinishedOrderList: (frNumber: string, dateRange?: DateRange): Promise<{ data: FetchOrdersResponse }> =>
            client.get(endpoints.order.list.finished, {
                params: {
                    frNumber,
                    ...(dateRange?.startDate && { startDate: dateRange.startDate.toDateString() }),
                    ...(dateRange?.endDate && { endDate: dateRange.endDate.toDateString() }),
                },
            }),
        integrate: (orderId: string): Promise<{ data: object }> => client.post(endpoints.order.integrate, { orderId }),
    },
    parameters: {
        changeRestaurantRight: (form: RestaurantPermissionDTO): Promise<{ data: object }> =>
            client.post(endpoints.parameters.permissions, form),
        setPermission: (form: ParameterPermissionForm): Promise<{ data: object }> =>
            client.post(endpoints.parameters.setPermission, form),
        getParameters: (frNumber: string): Promise<{ data: { parameterProfileInfos: ParameterProfileInfo[] } }> =>
            client.get(endpoints.parameters.fetch, { params: { frNumber } }),
    },
    kingTable: {
        postQrCodeInformations: async (payload: QrCodeDownloadRequest) => {
            return await client.post(endpoints.kingTable.post, payload, {
                responseType: 'arraybuffer',
            })
        },
    },
    featureToggle: {
        getFeatureToggle: async () => {
            const response = await client.get(endpoints.featureToggle.get)
            return response.data
        },
    },
    collaborators: {
        getAllEmployees: async (frNumber: string): Promise<CrmEmployeeDTO[]> => {
            const response = await client.get(endpoints.restaurant.allEmployees(frNumber))
            return response.data
        },
        postEmployee: async (frNumber: string, employeeData: CrmEmployeeForm): Promise<void> => {
            await client.post(endpoints.restaurant.allEmployees(frNumber), employeeData)
        },
        deleteEmployee: async (frNumber: string, id: string): Promise<void> => {
            await client.delete(endpoints.restaurant.employee(frNumber, id))
        },
        getEmployee: async (frNumber: string, id: string): Promise<EmployeeWithCouponDTO> => {
            const response = await client.get(endpoints.restaurant.employee(frNumber, id))
            return response.data
        },
        updateEmployee: async (frNumber: string, id: string, employeeData: CrmEmployeeForm): Promise<void> => {
            await client.put(endpoints.restaurant.employee(frNumber, id), employeeData)
        },
        resendMail: async (frNumber: string, id: string) => {
            const response = await client.get(endpoints.restaurant.resendMail(frNumber, id))
            return response.data
        },
    },
}
