import { camelCase, startCase } from 'lodash'
import {
    BirthdayBookingDetailsDTO,
    BirthdayBookingListItemDTO,
    CacOrderContent,
    ComplaintDTO,
    ComplaintLightDTO,
    ComplaintMessageDTO,
    OrderDTO,
    OrderLightDTO,
    ParameterProfileInfo,
    RestaurantDTO,
    RestaurantServiceForm,
} from 'types/api'
import {
    ComplaintStatus,
    DeliveryStatus,
    OrbStatus,
    OrderOrigin,
    OrderStatus,
    Permissions,
    PickUpType,
    RefundType,
    Role,
    ServiceCode,
} from 'types/api.enum'
import { DropDownItem } from 'types/dropdown-item'
import { SenderType, StatusType } from 'utils/enums'

export const canOrderBeReintegrated = (order: OrderLightDTO | OrderDTO, date: number): boolean => {
    return order.hasToBeReintegrated && new Date(order.hasToBeReintegratedDate) < new Date(date)
}

export const getOrderStatusType = (order: OrderLightDTO | OrderDTO, date?: number): StatusType => {
    const { orderOrigin, orbStatus, deliveryStatus, status } = order
    const dlvFinishedStatus = [
        DeliveryStatus.DELIVERING,
        DeliveryStatus.ALMOST_DELIVERING,
        DeliveryStatus.WAITING_AT_DROPOFF,
        DeliveryStatus.DELIVERED,
        DeliveryStatus.CANCELLED,
    ]
    const ccFinished = orderOrigin === OrderOrigin.CLICK_AND_COLLECT && orbStatus === OrbStatus.DELIVERED
    const dlvFinished =
        orderOrigin === OrderOrigin.DELIVERY_IN_APP &&
        (dlvFinishedStatus.includes(deliveryStatus as DeliveryStatus) || status === OrderStatus.STUART_ERROR)

    if (ccFinished || dlvFinished) {
        return StatusType.FINISHED
    }
    if (canOrderBeReintegrated(order, date || Date.now()) || orbStatus === OrbStatus.INTEGRATION_ERROR) {
        return StatusType.ERROR
    }
    return StatusType.IN_PROGRESS
}

export const getComplaintStatusType = (complaint: ComplaintLightDTO | ComplaintDTO): StatusType => {
    const toDoStatus = [
        ComplaintStatus.WAITING_RESTAURANT,
        ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER,
        ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER,
        ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER,
        ComplaintStatus.TO_REFUND,
    ]
    const finishedStatus = [
        ComplaintStatus.REFUNDED,
        ComplaintStatus.CLOSED,
        ComplaintStatus.NOTIFY_CLOSED,
        ComplaintStatus.NOTIFY_REFUNDED,
        ComplaintStatus.REFUNDED_MANUALLY,
        ComplaintStatus.CLOSED_MANUALLY,
    ]
    if (complaint.status && toDoStatus.includes(complaint.status)) {
        return StatusType.TO_DO
    }
    if (complaint.status && finishedStatus.includes(complaint.status)) {
        return StatusType.FINISHED
    }
    return StatusType.IN_PROGRESS
}

export const formatNumberTime = (value: string) => ('0' + value).slice(-2)

export const isClickAndCollect = (pickUpType?: PickUpType) => {
    const { ON_SITE, TABLE_SERVICE, PICK_UP } = PickUpType
    return pickUpType ? [ON_SITE, TABLE_SERVICE, PICK_UP].includes(pickUpType) : false
}

export const isKingTable = (pickUpType: PickUpType) => {
    const { TABLE_ORDER } = PickUpType
    return [TABLE_ORDER].includes(pickUpType)
}

export const isDriveAndParking = (pickUpType: PickUpType) => {
    const { PARKING, DRIVE } = PickUpType
    return [PARKING, DRIVE].includes(pickUpType)
}

export const formatDate = (date: Date): string => {
    return Intl.DateTimeFormat('fr-FR').format(date)
}

export const formatPrice = (price: number) =>
    price.toLocaleString('fr-FR', {
        style: 'currency',
        currency: 'EUR',
    })

export const formatTime = (date: Date): string => {
    return Intl.DateTimeFormat('fr-FR', { hour: 'numeric', minute: 'numeric' }).format(date)
}

export const formatDateTime = (date: Date): string => {
    return `${formatDate(new Date(date))} ${formatTime(new Date(date))}`
}

export const longFormatDateTime = (date: Date, capitalize = true): string => {
    const str = new Intl.DateTimeFormat('fr-FR', { dateStyle: 'full', timeStyle: 'short' })
        .format(date)
        .replace(':', 'h')
    return capitalize ? `${str[0].toUpperCase()}${str.slice(1)}` : str
}

export const getDeliveryPrice = (deliveryFees: CacOrderContent[] = []) => {
    return deliveryFees.reduce((acc, item) => acc + item?.finalPrice, 0)
}

export const sortByDate = (a: string, b: string) => new Date(b).getTime() - new Date(a).getTime()

export const getDiffMinuteBetweenDates = (endDate: Date, startDate: Date) => {
    return Math.trunc((startDate.getTime() - endDate.getTime()) / 60000)
}

export const percentageOff = (value: number, percentageValue: number) => {
    return value * (1 - percentageValue / 100)
}

export const isStringBoolean = (value: string) => {
    return value === 'true' || value === 'false'
}

export const formatTimeFromString = (value?: string) => value?.substring(0, value.length - 3)

export const isServiceActive = (serviceCode: ServiceCode, restaurant: RestaurantDTO | undefined) => {
    return restaurant?.services?.find((service) => serviceCode === service.code)?.active
}

export const getNewServices = (code: ServiceCode, restaurantFrNumber: string) => {
    const serviceParking: RestaurantServiceForm = {
        enabled: code === ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING,
        frNumber: restaurantFrNumber,
        serviceCode: ServiceCode.CLICK_AND_COLLECT_PICKUP_PARKING,
    }
    const serviceDrive: RestaurantServiceForm = {
        enabled: code === ServiceCode.CLICK_AND_COLLECT_DRIVE,
        frNumber: restaurantFrNumber,
        serviceCode: ServiceCode.CLICK_AND_COLLECT_DRIVE,
    }
    const serviceNoDriveParking: RestaurantServiceForm = {
        enabled: code === ServiceCode.NO_DRIVE_PARKING,
        frNumber: restaurantFrNumber,
        serviceCode: ServiceCode.NO_DRIVE_PARKING,
    }
    return { serviceParking, serviceDrive, serviceNoDriveParking }
}

export const isMoneyOrCrown = (refundType: RefundType) => (refundType === RefundType.CROWN ? 2 : 1)

export const isMoneyRefund = (refundType: RefundType) => refundType === RefundType.MONEY

export const isOrderNewError = (orderToCheck: OrderLightDTO, orders: OrderLightDTO[]) => {
    if (getOrderStatusType(orderToCheck) !== StatusType.ERROR) return false

    const order = orders.find((order) => order.id === orderToCheck.id)
    if (order) {
        return getOrderStatusType(order) === StatusType.IN_PROGRESS
    }

    return true
}

export const stringToDateDDMMYYYY = (dateString: string) => {
    if (!dateString) return null
    const dateParts = dateString.split('/')

    // month is 0-based, that's why we need dataParts[1] - 1
    return new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0])
}

export const getDateRangeFromString = (dateRange: string | null) => {
    return dateRange?.replaceAll('-', '/').split('_') || []
}

export const hasPermission = (userPermissions: Permissions[], permissionNeeded?: Permissions) => {
    if (!permissionNeeded) return true
    return userPermissions.includes(permissionNeeded)
}

export const createDateFromTime = (time: string) => {
    const [hour, minute] = time.split(':').map((value) => parseInt(value))
    const date = new Date()
    date.setHours(hour, minute)
    return date
}

export const createISODateTimeString = (date: string, time: string) => {
    const [day, month, year] = date.split('/').map((value) => parseInt(value))
    const [hour, minute] = time.split(':').map((value) => parseInt(value))
    const dateObj = new Date(year, month - 1, day, hour, minute)
    const isoString = dateObj.toISOString()
    return isoString
}

export const isPermissionActive = (accountPermissions: ParameterProfileInfo[], role: Role, permission: Permissions) => {
    if (!accountPermissions) return false
    const accountIndex = accountPermissions.findIndex((account) => account.profileCode === role)

    if (accountIndex >= 0) {
        const permissionIndex = accountPermissions[accountIndex].permissions?.findIndex(
            (perm: { code: Permissions }) => perm.code === permission,
        )
        if (permissionIndex && permissionIndex >= 0) {
            return !!accountPermissions[accountIndex].permissions?.[permissionIndex].active
        }
    }
    return false
}

export const getDopdownTimeListFromTimeLimit = (
    timeLimits: {
        startTime: string
        endTime: string
    },
    timeInterval: number,
) => {
    const { startTime, endTime } = timeLimits
    const startDate = createDateFromTime(startTime)
    const endDate = createDateFromTime(endTime)

    const dropDownOptions: Array<DropDownItem> = []
    const currentDate: Date = new Date(startDate)

    if (startDate >= endDate) {
        endDate.setDate(endDate.getDate() + 1)
    }
    while (currentDate <= endDate) {
        dropDownOptions.push({
            value: formatTime(currentDate),
            label: formatTime(currentDate),
        })
        currentDate.setMinutes(currentDate.getMinutes() + timeInterval)
    }
    return dropDownOptions
}

export function getHalfHourIntervals(start: string | null | undefined, end: string | null | undefined): string[] {
    if (start == null || end == null) {
        return []
    }

    const intervals: string[] = []
    const startDate = new Date(`1970-01-01T${start}:00.000Z`)
    const endDate = new Date(`1970-01-01T${end}:00.000Z`)
    const halfHourMs = 30 * 60 * 1000
    let currDate = startDate

    while (currDate <= endDate) {
        intervals.push(currDate.toISOString().slice(11, 16))
        currDate = new Date(currDate.getTime() + halfHourMs)
    }

    return intervals
}

export function isPast(date: Date) {
    const currentDate = new Date()
    return currentDate > date
}

export function combineDateAndTimeStringsIntoDate(dateString: string, timeString: string): Date {
    const [year, month, day] = dateString.split('-')
    const [hour, minute, second] = timeString.split(':')
    return new Date(
        parseInt(year),
        parseInt(month) - 1,
        parseInt(day),
        parseInt(hour),
        parseInt(minute),
        parseInt(second),
    )
}

export function createBirthdayFromDetails(birthdayDetails: BirthdayBookingDetailsDTO): BirthdayBookingListItemDTO {
    const birthday: BirthdayBookingListItemDTO = {
        id: birthdayDetails.id,
        vip: birthdayDetails.vip,
        status: birthdayDetails.status,
        reservationDate: birthdayDetails.reservationDate?.substring(0, 10),
        reservationHour: formatTime(new Date(birthdayDetails.reservationDate ?? '00')) + ':00',
        attendantPhoneNumber: birthdayDetails.attendantPhone,
        gift: birthdayDetails.gift,
        bookingNumber: birthdayDetails.bookingNumber,
    }
    return birthday
}

export function extractTableNumber(inputString: string): string {
    const tableNumberRegex = /tableNumber=T(\d+)&/
    const matches = inputString.match(tableNumberRegex)

    if (matches && matches.length > 1) {
        const tableNumber = matches[1]
        const sanitizedTableNumber = tableNumber.replace(/^0+/, '')
        return sanitizedTableNumber
    }

    return ''
}

export function formatBookingNumber(defaultId: string, bookingId?: string): string {
    const formatId = (bookingId && bookingId.padStart(4, '0')) || defaultId
    return formatId
}

export const formatPascalCase = (value?: string) => {
    return value ? startCase(camelCase(value)).replace(/ /g, ' ') : ''
}

export const cleanRestaurantName = (name: string | undefined) => {
    return name ? formatPascalCase(name.includes('BURGER KING ') ? name.split('BURGER KING ')[1] : name) : ''
}

export const getComplaintGlobalStatus = (status: ComplaintStatus | undefined) => {
    const waitingStatus = [
        ComplaintStatus.WAITING_RESTAURANT,
        ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER,
        ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER,
        ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER,
        ComplaintStatus.WAITING_CUSTOMER,
    ]

    const isAwaitingAction = !!status && waitingStatus.includes(status)
    const isRefunded = !isAwaitingAction
    return { isRefunded, isAwaitingAction }
}

export const getLastRestaurantMessageIndex = (messages: ComplaintMessageDTO[]) => {
    let lastRestaurantMessageIndex = -1

    messages?.forEach((message, index) => {
        if (message.sender === SenderType.RESTAURANT) {
            lastRestaurantMessageIndex = index
        }
    })

    return lastRestaurantMessageIndex
}
