import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
//import { ComplaintLightDTO } from "../../../../../types/api";
//import { StatusType } from "../../../../../utils/enums";
import styled from 'styled-components'

import { Exclamation } from '../../../../../assets/icons'
import { ErrorBlock } from '../../../../../components/error-block/ErrorBlock'
import { ShouldDisplay } from '../../../../../components/should-display/ShouldDisplay'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/useRedux'
import {
    selectNumberOfComplaintsByComplaintStatus,
    selectNumberOfComplaintsByMultipleComplaintStatus,
} from '../../../../../redux/complaint/selectors'
import { fetchComplaintsList } from '../../../../../redux/complaint/thunks'
import { selectPermissionsByFrNumber } from '../../../../../redux/user/selectors'
import { colors } from '../../../../../styles/colors'
import { BORDER, SPACING } from '../../../../../styles/tokens'
import { H4 } from '../../../../../styles/typography'
import { ComplaintStatus, Permissions } from '../../../../../types/api.enum'
import { hasPermission } from '../../../../../utils/util'
import { ColoredCard } from './components/ColoredCard'

export const ComplaintsSection = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true)
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))
    //const complaintsFinished: ComplaintLightDTO[] = useAppSelector((state) =>
    //    selectComplaintsByStatus(state, StatusType.FINISHED)
    //);
    //const complaintsInProgress: ComplaintLightDTO[] = useAppSelector((state) =>
    //    selectComplaintsByStatus(state, StatusType.IN_PROGRESS)
    //);
    const complaintsPermissions = hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)

    const dispatchComplaints = useCallback(() => {
        if (complaintsPermissions) {
            dispatch(fetchComplaintsList({ frNumber: restaurantFrNumber }))
                .catch((error: unknown) => setError(error))
                .finally(() => setIsLoading(false))
        }
    }, [complaintsPermissions, dispatch, restaurantFrNumber])

    useEffect(() => {
        if (restaurantFrNumber) {
            dispatchComplaints()
        }
    }, [restaurantFrNumber, dispatch, complaintsPermissions, dispatchComplaints])

    const toCheckComplaints = {
        textKey: 'page.home.dashboard.complaints.toCheckComplaints',
        number: useAppSelector((state) =>
            selectNumberOfComplaintsByMultipleComplaintStatus(state, [
                ComplaintStatus.WAITING_RESTAURANT,
                ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER,
                ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER,
                ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER,
            ]),
        ),
        url: `${t('page.complaints.route')}?stateInProgress=${ComplaintStatus.WAITING_RESTAURANT}-${
            ComplaintStatus.WAITING_RESTAURANT_FIRST_REMINDER
        }-${ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER}-${ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER}`,
    }

    const toPayBackComplaints = {
        textKey: 'page.home.dashboard.complaints.toPayBackComplaints',
        number: useAppSelector((state) => selectNumberOfComplaintsByComplaintStatus(state, ComplaintStatus.TO_REFUND)),
        url: `${t('page.complaints.route')}?stateInProgress=${ComplaintStatus.TO_REFUND}-${
            ComplaintStatus.REFUND_CONFIRMED
        }`,
    }

    /*const waitingConsoServiceComplaints = {
        textKey: "page.home.dashboard.complaints.waitingConsoService",
        number: useAppSelector((state) =>
            selectNumberOfComplaintsbyMultipleComplaintStatus(state, [
                ComplaintStatus.WAITING_SUPPORT,
                ComplaintStatus.REFUND_VALIDATED,
                ComplaintStatus.SYSTEM_VALIDATE_REFUND,
                ComplaintStatus.SYSTEM_CONTEST_REFUND,
                ComplaintStatus.NOTIFY_CONTEST_REFUND,
                ComplaintStatus.NOTIFY_VALIDATE_REFUND,
            ])
        ),
        url: `${t("page.complaints.route")}?stateFinished=${ComplaintStatus.WAITING_SUPPORT}-${
            ComplaintStatus.REFUND_VALIDATED
        }-${ComplaintStatus.SYSTEM_VALIDATE_REFUND}-${ComplaintStatus.SYSTEM_CONTEST_REFUND}-${
            ComplaintStatus.NOTIFY_CONTEST_REFUND
        }-${ComplaintStatus.NOTIFY_VALIDATE_REFUND}&status=${StatusType.FINISHED}`,
    };

    const payedBackComplaints = {
        textKey: "page.home.dashboard.complaints.payedBack",
        number: useAppSelector((state) =>
            selectNumberOfComplaintsbyComplaintStatus(state, ComplaintStatus.REFUNDED)
        ),
        url: `${t("page.complaints.route")}?stateFinished=${ComplaintStatus.REFUNDED}-${
            ComplaintStatus.REFUNDED_MANUALLY
        }&status=${StatusType.FINISHED}`,
    };*/

    if (isLoading) {
        return (
            <StyledMainContainer>
                <StyledCard>
                    <ClipLoader size={70} color={colors.orange} loading={true} />
                </StyledCard>
            </StyledMainContainer>
        )
    }

    if (error) {
        return (
            <StyledMainContainer>
                <StyledCard>
                    <ErrorBlock
                        disableImage
                        handleClick={dispatchComplaints}
                        labelButton={t('component.button.refreshContent')}
                    />
                </StyledCard>
            </StyledMainContainer>
        )
    }

    return (
        <ShouldDisplay condition={complaintsPermissions}>
            <StyledMainContainer>
                <StyledCard>
                    <StyledComplaintsTitle>
                        <StyledTitleContainer>
                            <Exclamation color={colors.brown} pointColor={colors.brown} size={24} />
                            <H4 color={colors.brown}>{t('page.home.dashboard.complaints.complaintsToCheck')}</H4>
                        </StyledTitleContainer>
                    </StyledComplaintsTitle>
                    <StyledComplaintsContainer>
                        <ColoredCard color={colors.redLight} element={toCheckComplaints} dotColor={colors.red} />
                        <ColoredCard
                            color={colors.orangeLight}
                            element={toPayBackComplaints}
                            dotColor={colors.orange}
                        />
                    </StyledComplaintsContainer>
                </StyledCard>
                {/*<StyledCard>     //TODO: will be reintegrated in a different way in a future batch (lot 2) - BE-9281
                    <StyledComplaintsTitle>
                        <StyledTitleContainer>
                            <CheckBoldCircleIcon size={24} />
                            <H4 color={colors.brown}>
                                {t("page.home.dashboard.complaints.checkedComplaints")}
                            </H4>
                        </StyledTitleContainer>
                    </StyledComplaintsTitle>
                    <StyledComplaintsContainer>
                        <ColoredCard
                            color={colors.orangeLight}
                            element={waitingConsoServiceComplaints}
                            dotColor={colors.orange}
                        />
                        <ColoredCard
                            color={colors.greenLight}
                            element={payedBackComplaints}
                            dotColor={colors.green}
                        />
                    </StyledComplaintsContainer>
                </StyledCard>*/}
            </StyledMainContainer>
        </ShouldDisplay>
    )
}

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${colors.white};
    padding: ${SPACING.XXS};
    border-radius: ${BORDER.RADIUS.S};
    box-sizing: border-box;
    ${BORDER.SHADOW_BOX};
    min-height: 170px;
`

const StyledComplaintsTitle = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${SPACING.S} ${SPACING.S} 0;
`

const StyledComplaintsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.S};
    padding: ${SPACING.S};
`

const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.M};
    padding: 0 ${SPACING.XXS};
`

const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXS};
    align-items: center;
`
