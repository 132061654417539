import { ComplaintBanner } from 'components/banner/ComplaintBanner'
import { ComplaintReminderModal } from 'components/complaint-reminder-modal/ComplaintReminderModal'
import { ErrorBlock } from 'components/error-block/ErrorBlock'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { selectNumberOfComplaintsByComplaintStatus } from 'redux/complaint/selectors'
import { fetchComplaintsList } from 'redux/complaint/thunks'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { ComplaintStatus, Permissions } from 'types/api.enum'
import { hasPermission } from 'utils/util'

export const ComplaintReminder = ({ children }: { children: ReactNode }) => {
    const { t } = useTranslation()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))

    const complaintsLastReminder = useAppSelector((state) =>
        selectNumberOfComplaintsByComplaintStatus(state, ComplaintStatus.WAITING_RESTAURANT_LAST_REMINDER),
    )

    const complaintsSecondReminder = useAppSelector((state) =>
        selectNumberOfComplaintsByComplaintStatus(state, ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER),
    )

    const [isOpen, setIsOpen] = useState(complaintsLastReminder > 0)
    const [bannerIsOpen, setBannerIsOpen] = useState(complaintsSecondReminder > 0)

    const toggleModal = () => {
        setIsOpen((x) => !x)
    }

    const handleClick = () => {
        navigate(t('page.complaints.route'))
        toggleModal()
    }

    const handleCloseBanner = () => {
        navigate(t('page.complaints.route'))
        setBannerIsOpen(false)
    }

    useEffect(() => {
        if (restaurantFrNumber) {
            if (hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)) {
                dispatch(fetchComplaintsList({ frNumber: restaurantFrNumber })).catch((error: unknown) => {
                    setError(error)
                })
            }
        }
    }, [dispatch, restaurantFrNumber, userPermissions])

    useEffect(() => {
        const isOpenValue = complaintsSecondReminder > 0
        setBannerIsOpen(isOpenValue)
    }, [complaintsSecondReminder])

    useEffect(() => {
        const isOpenValue = complaintsLastReminder > 0
        setIsOpen(isOpenValue)
    }, [complaintsLastReminder])

    if (error) {
        return <ErrorBlock errorCode={error?.response?.status} />
    }

    return (
        <>
            {children}
            {isOpen && <ComplaintReminderModal handleClose={toggleModal} onClick={handleClick} />}
            {bannerIsOpen && <ComplaintBanner onClick={handleCloseBanner} />}
        </>
    )
}
