import { createSelector } from '@reduxjs/toolkit'
import { ComplaintLightDTO } from 'types/api'
import { ComplaintType } from 'types/api.enum'
import { RootState } from 'types/redux'
import { StatusType } from 'utils/enums'
import { getComplaintStatusType } from 'utils/util'

export const selectComplaintsByStatus = createSelector(
    [(state: RootState) => state.complaint.complaints, (state: RootState, statusType: string) => statusType],
    (complaints: ComplaintLightDTO[] = [], statusType: string) => {
        return [...complaints].filter((complaint) => statusType === getComplaintStatusType(complaint))
    },
)

export const selectComplaintsWithMultipleFilters = createSelector(
    [
        (state: RootState) => state,
        (
            state: RootState,
            filter: {
                status?: StatusType
                complaintType?: ComplaintType
            },
        ) => filter,
    ],
    (state: RootState, filter) => {
        const { status, complaintType } = filter

        let filteredComplaints = selectComplaintsByStatus(state, status ?? StatusType.TO_DO)

        if (complaintType) {
            filteredComplaints = getComplaintByComplaintType(filteredComplaints, complaintType)
        }

        return filteredComplaints
    },
)

export const selectNumberOfComplaintsByComplaintStatus = createSelector(
    [(state: RootState) => state.complaint.complaints, (state: RootState, statusType: string) => statusType],
    (complaints: ComplaintLightDTO[] = [], statusType: string) => {
        const complaintsData = [...complaints].filter((complaint) => statusType === complaint.status)
        return complaintsData.length
    },
)

export const selectNumberOfComplaintsByMultipleComplaintStatus = createSelector(
    [(state: RootState) => state, (state: RootState, statusType: string[]) => statusType],
    (state: RootState = [], statusType: string[]) => {
        let totalLenght = 0
        statusType.map((status) => {
            const complaintLenght = selectNumberOfComplaintsByComplaintStatus(state, status)
            totalLenght = totalLenght + complaintLenght
            return totalLenght
        })
        return totalLenght
    },
)

const getComplaintByComplaintType = (complaints: ComplaintLightDTO[], complaintType: ComplaintType) => {
    if (complaintType.includes(ComplaintType.ALL)) {
        return complaints
    }
    return complaints.filter((complaint) => complaint.complaintType === complaintType)
}
