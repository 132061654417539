import { AppBar, AppBarProps } from '@mui/material'
import { ReactNode } from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'

type BannerProps = {
    children?: ReactNode
} & AppBarProps

export const Banner = ({ children, ...props }: BannerProps) => {
    return (
        <AppBar
            {...props}
            sx={{
                boxShadow: 'none',
                borderRadius: SPACING.XS,
                top: 0,
                backgroundColor: colors.red,
                ...props.sx,
            }}
        >
            {children && <StyledContentContainer>{children}</StyledContentContainer>}
        </AppBar>
    )
}

const StyledContentContainer = styled.div`
    display: flex;
    gap: ${SPACING.L};
    align-items: center;
    flex: 1;
    padding: ${SPACING.M} ${SPACING.M} ${SPACING.M} ${SPACING.XXL};
`
