import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { BoldCross, TrashIcon } from '../../assets/icons'
import { Button } from '../../components/button/Button'
import { GridColumnText, StyledColumn } from '../../components/grid-column-text/GridColumnText'
import { GridTitle } from '../../components/grid-title/GridTitle'
import { Modal } from '../../components/modal/Modal'
import { useFeatureToggle } from '../../hooks/useFeatureToggle'
import { useAppSelector } from '../../hooks/useRedux'
import { COLLABORATORS_KEYS, useDeleteEmployee, useGetAllEmployeesData } from '../../redux/collaborators/collaborators'
import { colors } from '../../styles/colors'
import { SPACING } from '../../styles/tokens'
import { H2, Text } from '../../styles/typography'
import { CrmEmployeeDTO, RestaurantLightDTO } from '../../types/api'
import { FEATURE_TOGGLE_CODE } from '../../utils/enums'
import { CollaboratorBanner } from '../home/components/collaborator-banner/CollaboratorBanner'
import { StyledGrid } from '../orders/style'
import {
    StyledButtonModalContainer,
    StyledButtonsContainer,
    StyledContainer,
    StyledDescription,
    StyledHeaderContainer,
    StyledMainContainer,
    StyledModalContainer,
    StyledModalContent,
    StyledModalMainContent,
    StyledTextContainer,
    StyledTitleContainer,
} from './style'

export const Collaborators = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const restaurantFrNumber = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const restaurants: RestaurantLightDTO[] = useAppSelector((state) => state.restaurant.restaurants)
    const restaurantName = restaurants.find((restaurant) => restaurant.frNumber === restaurantFrNumber)?.name ?? ''
    const employees = useGetAllEmployeesData(restaurantFrNumber).data
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [errorModalOpen, setErrorModalOpen] = useState(false)
    const [selectedCollaborator, setSelectedCollaborator] = useState<CrmEmployeeDTO>({
        lastName: '',
        firstName: '',
        email: '',
        employeeId: '',
        id: '',
    })

    const queryClient = useQueryClient()

    const { mutate: deleteEmployeeMutation } = useDeleteEmployee({
        onSuccess: () => {
            setConfirmModalOpen(false)
            queryClient.invalidateQueries({
                queryKey: [COLLABORATORS_KEYS.GET_ALL],
            })
        },
        onError: () => setErrorModalOpen(true),
    })

    const bannerCollaboratorFeatureToggle = useFeatureToggle([FEATURE_TOGGLE_CODE.TAB_COLLABORATOR_BANNER])

    return (
        <StyledMainContainer hasBanner={bannerCollaboratorFeatureToggle}>
            <StyledHeaderContainer>
                <StyledTitleContainer>
                    <H2 color={colors.brown}>{t('page.collaborators.employeePromotion.title')}</H2>
                    <Text size={SPACING.S} color={colors.brown}>
                        {t('page.collaborators.employeePromotion.description')}
                    </Text>
                </StyledTitleContainer>
                <Button
                    color={colors.red}
                    text={t('page.collaborators.employeePromotion.cta.addEmployee')}
                    onClick={() => navigate(t('page.collaborators.addEmployee.route'))}
                    style={{ height: SPACING.XXXXL }}
                />
            </StyledHeaderContainer>
            <StyledContainer>
                <StyledGrid>
                    <tbody>
                        <tr>
                            <GridTitle radiusLeft>
                                {t('page.collaborators.employeePromotion.employeeList.lastName')}
                            </GridTitle>
                            <GridTitle>{t('page.collaborators.employeePromotion.employeeList.firstName')}</GridTitle>
                            <GridTitle>{t('page.collaborators.employeePromotion.employeeList.mail')}</GridTitle>
                            <GridTitle>{t('page.collaborators.employeePromotion.employeeList.employeeId')}</GridTitle>
                            <GridTitle>
                                {t('page.collaborators.employeePromotion.employeeList.restaurantName')}
                            </GridTitle>
                            <GridTitle radiusRight />
                        </tr>
                    </tbody>
                    {employees &&
                        employees.map((employee, index) => {
                            return (
                                <tr key={index}>
                                    <GridColumnText value={employee.lastName ?? ''} color={colors.brown} />
                                    <GridColumnText value={employee.firstName ?? ''} color={colors.brown} />
                                    <GridColumnText value={employee.email ?? ''} color={colors.brown} />
                                    <GridColumnText value={employee.employeeId ?? ''} color={colors.brown} />
                                    <GridColumnText value={restaurantName} color={colors.brown} />
                                    <StyledColumn>
                                        <StyledButtonsContainer>
                                            <Button
                                                color={colors.red}
                                                text={t('page.collaborators.employeePromotion.cta.detailsEmployee')}
                                                onClick={() => navigate(`${employee.id ?? ''}`)}
                                            />
                                            <IconButton
                                                onClick={() => {
                                                    setConfirmModalOpen(true)
                                                    setSelectedCollaborator(employee)
                                                }}
                                            >
                                                <TrashIcon color={colors.brown} />
                                            </IconButton>
                                        </StyledButtonsContainer>
                                    </StyledColumn>
                                </tr>
                            )
                        })}
                </StyledGrid>
            </StyledContainer>
            {bannerCollaboratorFeatureToggle && <CollaboratorBanner />}
            {confirmModalOpen && (
                <Modal
                    handleClose={() => setConfirmModalOpen(false)}
                    closeIconColor={colors.brown}
                    center
                    canCloseOnClickOutside={false}
                >
                    <StyledModalContainer>
                        <StyledModalMainContent>
                            <H2 color={colors.brown}>{t('page.collaborators.deleteModal.title')}</H2>
                            <StyledModalContent>
                                <TrashIcon size={50} color={colors.brown} />
                                <StyledDescription>
                                    <StyledTextContainer>
                                        <Text>
                                            {t('page.collaborators.employeePromotion.employeeList.lastName') + ' :'}
                                        </Text>
                                        <Text>{selectedCollaborator.lastName}</Text>
                                    </StyledTextContainer>
                                    <StyledTextContainer>
                                        <Text>
                                            {t('page.collaborators.employeePromotion.employeeList.firstName') + ' :'}
                                        </Text>
                                        <Text>{selectedCollaborator.firstName}</Text>
                                    </StyledTextContainer>
                                    <StyledTextContainer>
                                        <Text>
                                            {t('page.collaborators.employeePromotion.employeeList.mail') + ' :'}
                                        </Text>
                                        <Text>{selectedCollaborator.email}</Text>
                                    </StyledTextContainer>
                                    <StyledTextContainer>
                                        <Text>
                                            {t('page.collaborators.employeePromotion.employeeList.restaurantName') +
                                                ' :'}
                                        </Text>
                                        <Text>{restaurantName}</Text>
                                    </StyledTextContainer>
                                    <StyledTextContainer>
                                        <Text>
                                            {t('page.collaborators.employeePromotion.employeeList.employeeId') + ' :'}
                                        </Text>
                                        <Text>{selectedCollaborator.employeeId}</Text>
                                    </StyledTextContainer>
                                </StyledDescription>
                            </StyledModalContent>
                        </StyledModalMainContent>
                        <StyledButtonModalContainer>
                            <Button
                                text={t('component.button.cancel')}
                                color={colors.black}
                                outlined
                                onClick={() => setConfirmModalOpen(false)}
                            />
                            <Button
                                text={t('component.button.validate')}
                                color={colors.red}
                                onClick={() =>
                                    deleteEmployeeMutation({
                                        frNumber: restaurantFrNumber,
                                        id: selectedCollaborator.id ?? '',
                                    })
                                }
                            />
                        </StyledButtonModalContainer>
                    </StyledModalContainer>
                </Modal>
            )}
            {errorModalOpen && (
                <Modal handleClose={() => setErrorModalOpen(false)} closeIconColor={colors.brown} center>
                    <StyledModalContainer>
                        <BoldCross color={colors.red} size={50} />
                        <H2 color={colors.brown}>{t('page.collaborators.deleteModal.error')}</H2>
                    </StyledModalContainer>
                </Modal>
            )}
        </StyledMainContainer>
    )
}
