import { Exclamation } from 'assets/icons'
import { Banner } from 'components/banner/Banner'
import { Button } from 'components/button/Button'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { SPACING } from 'styles/tokens'
import { H4 } from 'styles/typography'

interface CollaboratorBannerProps {
    onClick?: () => void
}

export const CollaboratorBanner = ({ onClick }: CollaboratorBannerProps) => {
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handleClick = () => {
        navigate(t('page.collaborators.route'))
        onClick && onClick()
    }

    return (
        <Banner
            position={'absolute'}
            sx={{
                bottom: 0,
                top: 'auto',
                paddingRight: '70px',
                borderRadius: `${SPACING.XS} ${SPACING.XS} 0 0 `,
                backgroundColor: colors.red,
            }}
        >
            <StyledBannerContainer>
                <StyledBannerContentContainer>
                    <Exclamation size={30} color={colors.white} pointColor={colors.white} />
                    <StyledTextContainer>
                        <H4 font={fonts.flame} onClick={handleClick} color={colors.beige}>
                            {t('page.home.dashboard.collaboratorsBanner.title')}
                        </H4>
                        <H4 font={fonts.flameSans} onClick={handleClick} color={colors.beige}>
                            {t('page.home.dashboard.collaboratorsBanner.description')}
                        </H4>
                    </StyledTextContainer>
                </StyledBannerContentContainer>

                {onClick && (
                    <Button
                        textColor={colors.brown}
                        color={colors.white}
                        text={t('page.home.dashboard.collaboratorsBanner.button')}
                        onClick={handleClick}
                    />
                )}
            </StyledBannerContainer>
        </Banner>
    )
}

const StyledBannerContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
`

const StyledBannerContentContainer = styled.div`
    display: flex;
    gap: ${SPACING.M};
    align-items: center;
`

const StyledTextContainer = styled.div`
    display: flex;
    gap: ${SPACING.XXXS};
    flex-direction: column;
`
