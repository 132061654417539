export enum slices {
    COMPLAINT = 'SLICE_COMPLAINT',
    ORDER = 'SLICE_ORDER',
    RESTAURANT = 'SLICE_RESTAURANT',
    USER = 'SLICE_USER',
    BIRTHDAY = 'SLICE_BIRTHDAY',
    KING_TABLE = 'SLICE_KING_TABLE',
}

export enum StatusType {
    ERROR = 'ERROR',
    IN_PROGRESS = 'IN_PROGRESS',
    FINISHED = 'FINISHED',
    COMING_SOON = 'COMING_SOON',
    ALL = 'ALL',
    TO_DO = 'TO_DO',
}

export enum NotyfType {
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum FilterType {
    STATUS = 'status',
    PICKUP = 'pickUpType',
    ONLY_ERROR = 'onlyError',
    DATE_RANGE = 'dateRange',
    STATE_IN_PROGRESS = 'stateInProgress',
    STATE_FINISHED = 'stateFinished',
}

export enum SenderType {
    RESTAURANT = 'RESTAURANT',
    CLIENT = 'CLIENT',
}

export enum LocalStorageKey {
    KING_TABLE_ROUTING = 'KING_TABLE_ROUTING',
    PERSIST_ROOT = 'persist:root',
    APP_VERSION = 'APP_VERSION',
    SAW_POWER_BI_BANNER = 'SAW_POWER_BI_BANNER',
    HAS_SEEN_COUPON_BANNER = 'HAS_SEEN_COUPON_BANNER',
    SAW_COLLABORATOR_BANNER = 'SAW_COLLABORATOR_BANNER',
}

export enum FEATURE_TOGGLE_CODE {
    TAB_EDIT_RESTAURANT_PREPARATION_TIME = 'TAB_EDIT_RESTAURANT_PREPARATION_TIME',
    TABLE_ORDER_QR_CODE = 'TABLE_ORDER_QR_CODE',
    FRANCHISED_RECLAMATION = 'FRANCHISED_RECLAMATION',
    BOB_COLLABORATEURS = 'BOB_COLLABORATEURS',
    DASHBOARD_POWER_BI = 'DASHBOARD_POWER_BI',
    COMPLAINT_PROMOTION_DOUBLE = 'COMPLAINT_PROMOTION_DOUBLE',
    TAB_COLLABORATOR_BANNER = 'TAB_COLLABORATOR_BANNER',
}

export enum TutorialTab {
    MANAGE_COMPLAINT = 'MANAGE_COMPLAINT',
    RESTAURANT_AND_CC = 'RESTAURANT_AND_CC',
    KING_DELIVERY = 'KING_DELIVERY',
}

export enum AccordionDetailsKey {
    DECRYPT_COMPLAINT = 'DECIPHER_COMPLAINT',
    HOW_IT_WORKS = 'HOW_IT_WORKS',
    GOAL = 'GOAL',
    REFUND_CUSTOMER = 'REFUND_CUSTOMER',
    CALL_CUSTOMER = 'CALL_CUSTOMER',
    CLOSE_COMPLAINT = 'CLOSE_COMPLAINT',
    REPORT_COMPLAINT = 'REPORT_COMPLAINT',
    EXCHANGE_CUSTOMER = 'EXCHANGE_CUSTOMER',
    NOTIFICATIONS = 'NOTIFICATIONS',
    VALIDATE_REFUND = 'Valider un remboursement',
    CONTEST_REFUND = 'Contester une réclamation',
    DELIVERY_CLAIM = 'Réclamation en responsabilité livraison',
}
