import { AuthRequired } from 'components/auth-required/AuthRequired'
import { ComplaintReminder } from 'components/complaint-reminder/ComplaintReminder'
import { ReactNode } from 'react'

import { Layout } from './Layout'

type PrivateLayoutProps = {
    children?: ReactNode
    showPreparationTime?: boolean
}

const PrivateLayout = ({ children, showPreparationTime = false }: PrivateLayoutProps) => {
    return (
        <AuthRequired>
            <ComplaintReminder>
                <Layout showPreparationTime={showPreparationTime}>{children}</Layout>
            </ComplaintReminder>
        </AuthRequired>
    )
}

export default PrivateLayout
