import { useFeatureToggle } from 'hooks/useFeatureToggle'
import { ReactElement } from 'react'
import { FEATURE_TOGGLE_CODE } from 'utils/enums'

type FeatureToggledRouteProps = {
    features: FEATURE_TOGGLE_CODE[]
    children: ReactElement
}

export const FeatureToggledRoute = ({ features, children }: FeatureToggledRouteProps) => {
    const featureEnabled = useFeatureToggle(features)

    if (featureEnabled) {
        return children
    }
    return null
}
