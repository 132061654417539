import { colors } from '../../styles/colors'
import { IconProps } from '../../types/icons'

export const GraphIcon = (props: IconProps) => {
    const { color = colors.white, size = 20, ...otherProps } = props

    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...otherProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.9412 9.9997C2.42139 9.9997 2 10.3579 2 10.7997V17.1996C2 17.6414 2.42139 17.9996 2.9412 17.9996C3.46102 17.9996 3.88241 17.6414 3.88241 17.1996V10.7997C3.88241 10.3579 3.46102 9.9997 2.9412 9.9997ZM7.64614 2C7.12633 2 6.70494 2.35817 6.70494 2.79999V17.1998C6.70494 17.6416 7.12633 17.9998 7.64614 17.9998C8.16595 17.9998 8.58734 17.6416 8.58734 17.1998V2.79999C8.58734 2.35817 8.16595 2 7.64614 2ZM17.0584 13.2001C16.5386 13.2001 16.1172 13.5582 16.1172 14V17.2C16.1172 17.6418 16.5386 18 17.0584 18C17.5782 18 17.9996 17.6418 17.9996 17.2V14C17.9996 13.5582 17.5782 13.2001 17.0584 13.2001ZM12.3533 6.79994C11.8335 6.79994 11.4121 7.15811 11.4121 7.59993V17.1998C11.4121 17.6416 11.8335 17.9998 12.3533 17.9998C12.8731 17.9998 13.2945 17.6416 13.2945 17.1998V7.59993C13.2945 7.15811 12.8731 6.79994 12.3533 6.79994Z"
                fill={color}
            />
        </svg>
    )
}
