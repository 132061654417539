import 'i18n/i18nProvider'

import { FeatureToggledRoute } from 'components/feature-toggle/FeatureToggledRoute'
import PrivateLayout from 'components/layout/PrivateLayout'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import { setAuthorizationHeader } from 'httpServices/client'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ActionType } from 'redux/store'
import { BirthdayManagement } from 'screens/birthday-management/BirthdayManagement'
import { BirthdayManagementDetails } from 'screens/birthday-management-details/BirthdayManagementDetails'
import { ComplaintDetail } from 'screens/complaint-detail/ComplaintDetail'
import { Complaints } from 'screens/complaints/Complaints'
import { ForgottenPassword } from 'screens/forgotten-password/ForgottenPassword'
import { Home } from 'screens/home/Home'
import { KingTableManagement } from 'screens/king-table-management/KingTableManagement'
import { Login } from 'screens/login/Login'
import { Orders } from 'screens/orders/Orders'
import { Parameters } from 'screens/parameters/Parameters'
import { Services } from 'screens/services/Services'
import { SetPassword } from 'screens/set-password/SetPassword'
import { Statistics } from 'screens/statistics/Statistics'
import { FEATURE_TOGGLE_CODE, LocalStorageKey } from 'utils/enums'

import { AddCollaborator } from './screens/collaborators/AddCollaborator'
import { Collaborators } from './screens/collaborators/Collaborators'
import { UpdateCollaborator } from './screens/collaborators/UpdateCollaborator'
import { RestaurantLightDTO } from './types/api'
import { sendTrackingEvent } from './utils/tracking'

const appVersion = process.env.REACT_APP_VERSION
const NEW_APP_VERSION = '1.12.6'

function App() {
    const { t } = useTranslation()
    const location = useLocation()
    const [loading, setLoading] = useState<boolean>(true)
    const token = useAppSelector((state) => state.user.token)
    const userRole = useAppSelector((state) => state.user.role)
    const selectedFrNumber: string = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const restaurants: RestaurantLightDTO[] = useAppSelector((state) => state.restaurant.restaurants)
    const restaurantName = restaurants.find((restaurant) => restaurant.frNumber === selectedFrNumber)?.name ?? ''
    const entryUri = location.pathname
    const dispatch = useAppDispatch()
    const cacheVersion = localStorage.getItem(LocalStorageKey.APP_VERSION)

    if (!token && entryUri === t('page.kingTable.route')) {
        localStorage.setItem(LocalStorageKey.KING_TABLE_ROUTING, 'true')
    }

    if (!cacheVersion && appVersion) {
        localStorage.setItem(LocalStorageKey.APP_VERSION, appVersion)
    }

    if (!cacheVersion || cacheVersion <= NEW_APP_VERSION) {
        dispatch({ type: ActionType.RESET_REDUX_CACHE })

        if (appVersion) {
            localStorage.setItem(LocalStorageKey.APP_VERSION, appVersion)
        }
    }

    useEffect(() => {
        if (token) {
            setAuthorizationHeader(token)
        }
        setLoading(false)
    }, [token])

    useEffect(() => {
        if (restaurantName && userRole) {
            sendTrackingEvent(restaurantName, userRole)
        }
    }, [restaurantName, userRole, location])

    if (loading) {
        return null
    }

    return (
        <Routes>
            <Route path={t('page.login.route')} element={<Login />} />
            <Route path={t('page.setPassword.route')} element={<SetPassword />} />
            <Route path={t('page.forgottenPassword.route')} element={<ForgottenPassword />} />
            <Route
                path={t('page.home.route')}
                element={
                    <PrivateLayout>
                        <Home />
                    </PrivateLayout>
                }
            />
            <Route
                path={t('page.orders.route')}
                element={
                    <PrivateLayout showPreparationTime>
                        <Orders />
                    </PrivateLayout>
                }
            />
            <Route
                path={t('page.complaints.route')}
                element={
                    <PrivateLayout>
                        <Complaints />
                    </PrivateLayout>
                }
            />
            <Route
                path={t('page.complaints.routeDetail')}
                element={
                    <PrivateLayout>
                        <ComplaintDetail />
                    </PrivateLayout>
                }
            />
            <Route
                path={t('page.parameters.route')}
                element={
                    <PrivateLayout>
                        <Parameters />
                    </PrivateLayout>
                }
            />
            <Route
                path={t('page.services.route')}
                element={
                    <PrivateLayout>
                        <Services />
                    </PrivateLayout>
                }
            />
            <Route
                path={t('page.birthday.route')}
                element={
                    <PrivateLayout>
                        <BirthdayManagement />
                    </PrivateLayout>
                }
            />
            <Route
                path={t('page.birthday.routeDetail')}
                element={
                    <PrivateLayout>
                        <BirthdayManagementDetails />
                    </PrivateLayout>
                }
            />

            <Route
                path={t('page.kingTable.route')}
                element={
                    <PrivateLayout>
                        <KingTableManagement />
                    </PrivateLayout>
                }
            />

            <Route
                path={t('page.statistics.route')}
                element={
                    <FeatureToggledRoute features={[FEATURE_TOGGLE_CODE.DASHBOARD_POWER_BI]}>
                        <PrivateLayout>
                            <Statistics />
                        </PrivateLayout>
                    </FeatureToggledRoute>
                }
            />

            <Route
                path={t('page.collaborators.route')}
                element={
                    <FeatureToggledRoute features={[FEATURE_TOGGLE_CODE.BOB_COLLABORATEURS]}>
                        <PrivateLayout>
                            <Collaborators />
                        </PrivateLayout>
                    </FeatureToggledRoute>
                }
            />

            <Route
                path={t('page.collaborators.addEmployee.route')}
                element={
                    <FeatureToggledRoute features={[FEATURE_TOGGLE_CODE.BOB_COLLABORATEURS]}>
                        <PrivateLayout>
                            <AddCollaborator />
                        </PrivateLayout>
                    </FeatureToggledRoute>
                }
            />

            <Route
                path={t('page.collaborators.routeDetail')}
                element={
                    <FeatureToggledRoute features={[FEATURE_TOGGLE_CODE.BOB_COLLABORATEURS]}>
                        <PrivateLayout>
                            <UpdateCollaborator />
                        </PrivateLayout>
                    </FeatureToggledRoute>
                }
            />
        </Routes>
    )
}

export default App
