import styled from 'styled-components'

import Tutorial from '../../screens/tutorial/Tutorial'
import { colors } from '../../styles/colors'
import { BORDER, SPACING } from '../../styles/tokens'
import { TutorialTab } from '../../utils/enums'
import { Modal } from '../modal/Modal'

type ComplaintsTutorialModalProps = {
    handleClose: () => void
    activeTab?: TutorialTab
}

export const ComplaintsTutorialModal = ({ handleClose, activeTab }: ComplaintsTutorialModalProps) => {
    return (
        <Modal handleClose={handleClose}>
            <StyledModalContainer>
                <Tutorial activeTab={activeTab} />
            </StyledModalContainer>
        </Modal>
    )
}

const StyledModalContainer = styled.div`
    width: 90vw;
    background-color: ${colors.white};
    padding: ${SPACING.XXL};
    box-sizing: border-box;
    border-radius: ${BORDER.RADIUS.XXXXL};
    height: 90vh;
`
