import { Form } from 'formik'
import styled from 'styled-components'

import { Button } from '../../components/button/Button'
import { colors } from '../../styles/colors'
import { BORDER, SPACING } from '../../styles/tokens'

export const StyledMainContainer = styled.div<{ hasBanner?: boolean }>`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    ${(props) => props.hasBanner && `padding-bottom: 90px;`};
`

export const StyledContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${colors.white};
    border-radius: ${BORDER.RADIUS.XS};
    padding: ${SPACING.L};
`

export const StyledHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: ${SPACING.L};
    justify-content: space-between;
    align-items: center;
`

export const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${SPACING.XXS};
    margin: 0 ${SPACING.S};
`

export const StyledFormTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXS};
    align-items: center;
`

export const StyledButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: ${SPACING.M};
`

export const StyledFormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
`

export const StyledInputsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XS};
    justify-content: flex-start;
    width: 50%;
    padding-bottom: ${SPACING.XS};
`

export const StyledModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.white};
    border-radius: ${BORDER.RADIUS.XS};
    padding: ${SPACING.XXL};
    gap: ${SPACING.M};
    align-items: center;
`

export const StyledButtonModalContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: ${SPACING.S};
    padding-bottom: ${SPACING.XXS};
`

export const StyledModalMainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${SPACING.XL} ${SPACING.XL} ${SPACING.XS};
    gap: ${SPACING.L};
`

export const StyledTextContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXS};
`

export const StyledDescription = styled.div`
    display: flex;
    flex-direction: column;
`

export const StyledModalContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.M};
    align-items: center;
`

export const StyledLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

export const StyledButton = styled(Button)`
    margin-top: ${SPACING.M};
    max-width: 180px;
`
