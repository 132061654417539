import { useAppSelector } from 'hooks/useRedux'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { NoPermissions } from 'screens/no-permissions/NoPermissions'
import { Permissions } from 'types/api.enum'
import { hasPermission } from 'utils/util'

import { PowerBI } from './power-bi/PowerBI'

export const Statistics = () => {
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))

    if (!hasPermission(userPermissions, Permissions.SHOW_POWER_BI_DASHBOARD)) {
        return <NoPermissions />
    }

    return (
        <>
            <PowerBI />
        </>
    )
}
