declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataLayer: any
    }
}

export const sendTrackingEvent = (restaurantName: string, user_role: string) => {
    window.dataLayer = window.dataLayer || []
    if (window && window.dataLayer) {
        window?.dataLayer.push({
            restaurant_name: restaurantName,
            user_role: user_role,
        })
    }
    return
}
