import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { ComplaintType } from 'types/api.enum'

import { ClickAndCollectIcon, DeliveryIcon, ShopIcon } from '../../assets/icons'
import { StyledFilterButton } from './style'

type Props = {
    values: ComplaintType
    selected: boolean
    onClick: (values: ComplaintType) => void
}

export const FilterButton = ({ values, selected, onClick }: Props) => {
    const { t } = useTranslation()
    const filterIcons = {
        [ComplaintType.DELIVERY]: <DeliveryIcon color={selected ? colors.white : colors.brown} />,
        [ComplaintType.RESTAURANT]: <ShopIcon color={selected ? colors.white : colors.brown} />,
        [ComplaintType.CLICK_AND_COLLECT]: <ClickAndCollectIcon color={selected ? colors.white : colors.brown} />,
    }

    return (
        <StyledFilterButton selected={selected} onClick={() => onClick(values)}>
            {values !== ComplaintType.ALL && filterIcons[values]}
            <Text color={selected ? colors.white : colors.brown}>{t(`complaints.filter.${values}`)}</Text>
        </StyledFilterButton>
    )
}
