import { Button } from 'components/button/Button'
import { useClickOutside } from 'hooks/useClickOutside'
import { ReactNode, RefObject, useCallback, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { H4 } from 'styles/typography'

interface DropdownButtonListItemProps {
    text: string
    onClick: () => void
}

interface DropdownButtonProps {
    icon?: ReactNode
    list: DropdownButtonListItemProps[]
}

export const DropdownButton = ({ icon, list }: DropdownButtonProps) => {
    const ref: RefObject<HTMLInputElement> = useRef(null)
    const [isOpen, setIsOpen] = useState(false)

    useClickOutside(ref, () => setIsOpen(false))

    const toggleDropdown = useCallback(() => {
        setIsOpen(!isOpen)
    }, [isOpen])

    const handleItemClick = (onClick: () => void) => {
        onClick()
        setIsOpen(false)
    }

    return (
        <StyledMainContainer>
            <StyledButton color={colors.white} outlined icon={icon} onClick={toggleDropdown} />
            {isOpen && (
                <StyledDropdownModal ref={ref}>
                    {list.map((item) => (
                        <StyledDropdownItem onClick={() => handleItemClick(item.onClick)} key={item.text}>
                            <H4 font={fonts.flameSans} color={colors.blackLight}>
                                {item.text}
                            </H4>
                        </StyledDropdownItem>
                    ))}
                </StyledDropdownModal>
            )}
        </StyledMainContainer>
    )
}

const StyledMainContainer = styled.div`
    position: relative;
`

const StyledDropdownModal = styled.div`
    z-index: 1;
    background: ${colors.white};
    color: ${colors.black};
    box-shadow: 0px 2px 24px ${colors.shadowDark};
    border-radius: ${BORDER.RADIUS.XS};
    max-width: 500px;
    max-height: 240px;
    overflow-y: auto;
    margin-top: ${SPACING.XS};
    padding: ${SPACING.XS} ${SPACING.NONE};
    position: absolute;
    width: 300px;
    right: 0;
`

const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
    height: 100%;
    padding: ${SPACING.XXS};
`

const StyledDropdownItem = styled.span`
    display: block;
    padding: ${SPACING.XS} ${SPACING.L};
    &:hover {
        background: ${colors.shadow};
        cursor: pointer;
    }
`
