import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ArrowRightIcon } from '../../../../../../assets/icons'
import { Spacer } from '../../../../../../components/spacer'
import { colors } from '../../../../../../styles/colors'
import { fonts } from '../../../../../../styles/fonts'
import { BORDER, SPACING } from '../../../../../../styles/tokens'
import { H4, Text } from '../../../../../../styles/typography'
import { CardProps } from '../../../../../../types/card'

export type CardsProps = {
    title: string
    icon: JSX.Element
    receivedOrders: CardProps
    currentOrders: CardProps
    completeOrders: CardProps
}

export const TripleCards = ({ title, icon, receivedOrders, currentOrders, completeOrders }: CardsProps) => {
    return (
        <StyledCard>
            <StyledH4Container>
                <div>{icon}</div>
                <H4 color={colors.brown}>{title}</H4>
            </StyledH4Container>
            <StyledMainContainer>
                <Spacer />
                <CardElement element={receivedOrders} dotColor={colors.green} />
                <StyledVerticalLine />
                <CardElement element={currentOrders} dotColor={colors.orange} />
                <StyledVerticalLine />
                <CardElement element={completeOrders} dotColor={colors.defaultLightGrey} />
                <Spacer />
            </StyledMainContainer>
        </StyledCard>
    )
}

export type CardElementProps = {
    element: CardProps
    dotColor: string
}

export const CardElement = ({ element, dotColor }: CardElementProps) => {
    const navigate = useNavigate()
    const { t } = useTranslation()

    return (
        <StyledContainer onClick={() => navigate(element.url)}>
            <StyledElement>
                <Text color={colors.brown} size={SPACING.XXXL} font={fonts.flame}>
                    {element.number}
                </Text>
                <StyledDot color={dotColor} />
            </StyledElement>
            <StyledElement>
                <Text color={colors.brown}>{t(element.textKey, { count: element.number })}</Text>
                <ArrowRightIcon size={25} color={colors.brown} />
            </StyledElement>
        </StyledContainer>
    )
}

const StyledElement = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXXS};
    align-items: center;
`

const StyledDot = styled.span`
    width: ${SPACING.XXS};
    height: ${SPACING.XXS};
    border-radius: ${BORDER.RADIUS.XS};
    background: ${(props) => props.color};
    box-shadow: ${SPACING.NONE} ${SPACING.NONE} ${SPACING.XXXS} ${(props) => props.color};
    margin: ${SPACING.XXXXS} 0 0 ${SPACING.XXS};
`

const StyledH4Container = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXS};
    padding: ${SPACING.L};
    align-content: center;
`

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${SPACING.XXS};
    padding: 0 ${SPACING.S} ${SPACING.S};
    &:hover {
        cursor: pointer;
    }
`

const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${colors.white};
    border-radius: ${BORDER.RADIUS.S};
    ${BORDER.SHADOW_BOX};
`

const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: ${SPACING.L};
`

const StyledVerticalLine = styled.div`
    background-color: ${colors.beige};
    width: ${BORDER.WIDTH.XS};
`
