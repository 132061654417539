import { useState } from 'react'
import { LocalStorageKey } from 'utils/enums'

import { useAppSelector } from './useRedux'

export const useOpenOnceBanner = (localStorageKey: LocalStorageKey) => {
    const { email } = useAppSelector((state) => state.user)

    const storageKey = `${localStorageKey}_${email}`

    const alreadySawPowerBiBanner = localStorage.getItem(storageKey) === 'true'

    const [displayBanner, setDisplayBanner] = useState(alreadySawPowerBiBanner ? !alreadySawPowerBiBanner : true)

    const handleClick = () => {
        localStorage.setItem(storageKey, 'true')
        setDisplayBanner(false)
    }

    return { handleClick, displayBanner }
}
