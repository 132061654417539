/*<svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M0 7.05486C0.248407 8.71643 2.00637 10.0903 4.38535 10.6379C4.17354 10.1924 4.01333 9.72542 3.90764 9.24554C2.40789 8.91394 1.04601 8.15045 0 7.05486H0Z"
        fill="#FFAA00"/>
    <path
        d="M5.12101 11.8631C3.16988 11.6795 1.35203 10.8194 0 9.44039C0.315286 11.5475 3.04777 13.1998 6.41082 13.2741C5.91851 12.8613 5.48479 12.3868 5.12101 11.8631Z"
        fill="#FFAA00"/>
    <path
        d="M0.267516 5.44888L0.410828 5.70879V5.80162L0.573248 6.04296L0.649681 6.13579L0.840763 6.36785L0.955413 6.49781C1.19334 6.73876 1.45227 6.95927 1.7293 7.15687L1.91083 7.21257L2.14968 7.36109L2.34076 7.46319C2.41708 7.51201 2.4969 7.55544 2.57962 7.59315L2.80891 7.70454L3.04777 7.80665L3.3535 7.92732L3.54458 8.00158L3.78344 8.07584C3.78344 7.90876 3.78344 7.74167 3.78344 7.57459C3.7816 5.95245 4.3191 4.37312 5.3159 3.0718C6.31269 1.77048 7.71545 0.816792 9.31528 0.352753C8.46014 0.119273 7.57615 0.000592676 6.68789 1.52588e-05C2.99044 1.52588e-05 0 1.90294 0 4.25143C0.00198309 4.66436 0.0931182 5.0723 0.267516 5.44888Z"
        fill="#FFAA00"/>
    <path
        d="M11.465 1.2254C10.1725 1.2254 8.90897 1.59777 7.83429 2.29544C6.75962 2.99311 5.922 3.98473 5.42738 5.14491C4.93276 6.30508 4.80335 7.58171 5.0555 8.81335C5.30766 10.045 5.93006 11.1763 6.844 12.0643C7.75794 12.9522 8.92237 13.557 10.19 13.8019C11.4577 14.0469 12.7717 13.9212 13.9658 13.4406C15.1599 12.9601 16.1806 12.1463 16.8986 11.1021C17.6167 10.058 18 8.83043 18 7.57467C18 5.89074 17.3115 4.27577 16.0859 3.08505C14.8604 1.89433 13.1982 1.2254 11.465 1.2254ZM13.8057 7.72319C13.5638 7.81822 13.3278 7.9267 13.0987 8.04808C13.1892 8.52909 13.3334 8.99909 13.5287 9.44975C13.7962 10.0345 13.2802 10.4523 12.6974 10.1367C12.2674 9.87905 11.8557 9.59374 11.465 9.28266C11.1647 9.52144 10.8777 9.77552 10.6051 10.0438C10.3376 10.3316 10.0223 10.5822 9.76432 10.4615C9.64802 10.3787 9.56147 10.2624 9.51689 10.1292C9.47232 9.9959 9.47198 9.8524 9.51592 9.71894C9.5906 9.21206 9.70885 8.71215 9.86942 8.22445C9.57324 8.04808 9.19108 7.74175 8.91401 7.52825C8.80239 7.45657 8.71956 7.34975 8.67943 7.22574C8.6393 7.10174 8.6443 6.96809 8.69361 6.84724C8.74291 6.72639 8.83351 6.62571 8.9502 6.5621C9.06688 6.49848 9.20254 6.47581 9.33439 6.49789C9.72453 6.54614 10.1194 6.54614 10.5095 6.49789C10.6301 6.0612 10.79 5.63566 10.9873 5.22618C11.3025 4.5764 11.8758 4.65066 12.0764 5.22618L12.6019 6.53502H13.7006C13.7792 6.52603 13.8589 6.53243 13.9349 6.55385C14.0109 6.57527 14.0817 6.61127 14.1432 6.65972C14.2046 6.70818 14.2555 6.76811 14.2927 6.83597C14.3299 6.90384 14.3527 6.97826 14.3599 7.05484C14.3623 7.21309 14.3113 7.36774 14.2147 7.49515C14.118 7.62256 13.9811 7.7157 13.8248 7.76032L13.8057 7.72319Z"
        fill="#FFAA00"/>
</svg>*/

import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { yellowMelty } = colors

export const MoneyIcon = (props: IconProps) => {
    const { color = yellowMelty, size = 18, ...otherProps } = props
    return (
        <svg
            width={size}
            height={size - 4}
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...otherProps}
        >
            <path
                d="M0 7.05486C0.248407 8.71643 2.00637 10.0903 4.38535 10.6379C4.17354 10.1924 4.01333 9.72542 3.90764 9.24554C2.40789 8.91394 1.04601 8.15045 0 7.05486H0Z"
                fill={color}
            />
            <path
                d="M5.12101 11.8631C3.16988 11.6795 1.35203 10.8194 0 9.44039C0.315286 11.5475 3.04777 13.1998 6.41082 13.2741C5.91851 12.8613 5.48479 12.3868 5.12101 11.8631Z"
                fill={color}
            />
            <path
                d="M0.267516 5.44888L0.410828 5.70879V5.80162L0.573248 6.04296L0.649681 6.13579L0.840763 6.36785L0.955413 6.49781C1.19334 6.73876 1.45227 6.95927 1.7293 7.15687L1.91083 7.21257L2.14968 7.36109L2.34076 7.46319C2.41708 7.51201 2.4969 7.55544 2.57962 7.59315L2.80891 7.70454L3.04777 7.80665L3.3535 7.92732L3.54458 8.00158L3.78344 8.07584C3.78344 7.90876 3.78344 7.74167 3.78344 7.57459C3.7816 5.95245 4.3191 4.37312 5.3159 3.0718C6.31269 1.77048 7.71545 0.816792 9.31528 0.352753C8.46014 0.119273 7.57615 0.000592676 6.68789 1.52588e-05C2.99044 1.52588e-05 0 1.90294 0 4.25143C0.00198309 4.66436 0.0931182 5.0723 0.267516 5.44888Z"
                fill={color}
            />
            <path
                d="M11.465 1.2254C10.1725 1.2254 8.90897 1.59777 7.83429 2.29544C6.75962 2.99311 5.922 3.98473 5.42738 5.14491C4.93276 6.30508 4.80335 7.58171 5.0555 8.81335C5.30766 10.045 5.93006 11.1763 6.844 12.0643C7.75794 12.9522 8.92237 13.557 10.19 13.8019C11.4577 14.0469 12.7717 13.9212 13.9658 13.4406C15.1599 12.9601 16.1806 12.1463 16.8986 11.1021C17.6167 10.058 18 8.83043 18 7.57467C18 5.89074 17.3115 4.27577 16.0859 3.08505C14.8604 1.89433 13.1982 1.2254 11.465 1.2254ZM13.8057 7.72319C13.5638 7.81822 13.3278 7.9267 13.0987 8.04808C13.1892 8.52909 13.3334 8.99909 13.5287 9.44975C13.7962 10.0345 13.2802 10.4523 12.6974 10.1367C12.2674 9.87905 11.8557 9.59374 11.465 9.28266C11.1647 9.52144 10.8777 9.77552 10.6051 10.0438C10.3376 10.3316 10.0223 10.5822 9.76432 10.4615C9.64802 10.3787 9.56147 10.2624 9.51689 10.1292C9.47232 9.9959 9.47198 9.8524 9.51592 9.71894C9.5906 9.21206 9.70885 8.71215 9.86942 8.22445C9.57324 8.04808 9.19108 7.74175 8.91401 7.52825C8.80239 7.45657 8.71956 7.34975 8.67943 7.22574C8.6393 7.10174 8.6443 6.96809 8.69361 6.84724C8.74291 6.72639 8.83351 6.62571 8.9502 6.5621C9.06688 6.49848 9.20254 6.47581 9.33439 6.49789C9.72453 6.54614 10.1194 6.54614 10.5095 6.49789C10.6301 6.0612 10.79 5.63566 10.9873 5.22618C11.3025 4.5764 11.8758 4.65066 12.0764 5.22618L12.6019 6.53502H13.7006C13.7792 6.52603 13.8589 6.53243 13.9349 6.55385C14.0109 6.57527 14.0817 6.61127 14.1432 6.65972C14.2046 6.70818 14.2555 6.76811 14.2927 6.83597C14.3299 6.90384 14.3527 6.97826 14.3599 7.05484C14.3623 7.21309 14.3113 7.36774 14.2147 7.49515C14.118 7.62256 13.9811 7.7157 13.8248 7.76032L13.8057 7.72319Z"
                fill={color}
            />
        </svg>
    )
}
