import { Hourglass } from 'assets/icons'
import { Status } from 'components/status/Status'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { Text } from 'styles/typography'
import { ComplaintDTO } from 'types/api'
import { ComplaintStatus, RefundType, Responsibility } from 'types/api.enum'
import { formatNumberTime, getComplaintStatusType, isMoneyRefund } from 'utils/util'

import { StatusType } from '../../../utils/enums'

type StatusRenderProps = {
    status: ComplaintStatus
    statusLabel: string
    refundType: RefundType | null
    endDate: string
    complaint: ComplaintDTO
}

export const StatusRender = ({ status, statusLabel, refundType, endDate, complaint }: StatusRenderProps) => {
    const { t } = useTranslation()

    const TimeRenderer = ({
        formatted,
    }: {
        formatted: {
            hours: string
            minutes: string
        }
    }) => {
        const { hours, minutes } = formatted
        return (
            <StyledStatusTime>
                <Hourglass color={colors.red} />
                <Text color={colors.blackLight}>
                    {`${formatNumberTime(hours)}:${formatNumberTime(minutes)} ${t('time.left', {
                        count: Number(hours),
                    })}`}
                </Text>
            </StyledStatusTime>
        )
    }

    switch (status) {
        case ComplaintStatus.WAITING_RESTAURANT:
            return <Countdown date={new Date(endDate)} daysInHours renderer={TimeRenderer} />
        case ComplaintStatus.TO_REFUND:
            return (
                <>
                    {refundType && isMoneyRefund(refundType) && (
                        <Countdown date={new Date(endDate)} daysInHours renderer={TimeRenderer} />
                    )}

                    <Status
                        type={
                            complaint.inCharge === Responsibility.DELIVERY &&
                            getComplaintStatusType(complaint) !== StatusType.FINISHED
                                ? Responsibility.DELIVERY
                                : getComplaintStatusType(complaint)
                        }
                        text={statusLabel}
                    />
                </>
            )
        default:
            return (
                <Status
                    type={
                        complaint.inCharge === Responsibility.DELIVERY &&
                        getComplaintStatusType(complaint) !== StatusType.FINISHED
                            ? Responsibility.DELIVERY
                            : getComplaintStatusType(complaint)
                    }
                    text={statusLabel}
                />
            )
    }
}

const StyledStatusTime = styled.div`
    display: flex;
    flex-direction: row;
    background: ${colors.redLight};
    border-radius: 5px;
    height: fit-content;
    padding: 3px 12px;
    align-items: center;
`
