import { Chevron } from 'assets/icons'
import { ErrorBlock } from 'components/error-block/ErrorBlock'
import { useCurrentComplaint } from 'hooks/useCurrentComplaint'
import { useFeatureToggle } from 'hooks/useFeatureToggle'
import { useAppDispatch, useAppSelector } from 'hooks/useRedux'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { setCurrentComplaint } from 'redux/complaint/complaintSlice'
import { selectNumberOfComplaintsByComplaintStatus } from 'redux/complaint/selectors'
import { fetchComplaint } from 'redux/complaint/thunks'
import { selectCurrentRestaurant, selectRestaurantsbyName } from 'redux/restaurant/selectors'
import { selectPermissionsByFrNumber } from 'redux/user/selectors'
import { NoPermissions } from 'screens/no-permissions/NoPermissions'
import { colors } from 'styles/colors'
import { H2 } from 'styles/typography'
import { ComplaintDTO } from 'types/api'
import { ComplaintStatus, ComplaintType, Permissions, Responsibility } from 'types/api.enum'
import { FEATURE_TOGGLE_CODE } from 'utils/enums'
import { getComplaintStatusType, hasPermission, isClickAndCollect, isMoneyOrCrown } from 'utils/util'

import { ClickAndCollectComplaintDetail } from './ClickAndCollectComplaintDetail'
import { ButtonsRender, StatusRender } from './components'
import TutorialBanner from './components/TutorialBanner'
import { DeliveryComplaintDetail } from './DeliveryComplaintDetail'
import { RestaurantComplaintDetail } from './RestaurantComplaintDetail'
import {
    StyledButtonContainer,
    StyledCardContainer,
    StyledContainer,
    StyledContent,
    StyledHeader,
    StyledReturnButton,
    StyledStatusContainer,
    StyledTitle,
} from './style'

export const chatCustomData = (complaint: ComplaintDTO, restaurant?: { name: string }) => [
    { key: 'complaintNumber', value: complaint.complaintNumber },
    { key: 'agentId', value: complaint.agentId },
    { key: 'chatId', value: complaint.chatId },
    { key: 'restaurantName', value: restaurant?.name },
    {
        key: 'provider',
        value: isClickAndCollect(complaint.order?.pickUpType) ? 'CLICK_AND_COLLECT' : complaint.order?.pickUpType,
    },
    { key: 'responsibility', value: complaint.inCharge },
    { key: 'order_number', value: complaint.order?.orderNumber },
]

export const ComplaintDetail = () => {
    const { complaintId = '' } = useParams()
    const [pending, setPending] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [error, setError] = useState<any>(null)
    const userPermissions: Permissions[] = useAppSelector((state) => selectPermissionsByFrNumber(state))

    useEffect(() => {
        const eventTypes = [
            // "Botmind_ChatOpened",
            // "Botmind_ChatClosed",
            // "Botmind_MessageSent",
            // "Botmind_MessageReceived",
            'Botmind_MessageReceivedFromBot',
        ]
        const refreshComplaint = _.debounce(async () => {
            if (hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)) {
                const response = await dispatch<ComplaintDTO | void>(
                    // @ts-ignore
                    fetchComplaint(complaintId),
                )
                if (response) {
                    dispatch(setCurrentComplaint(response))
                }
            }
        }, 1000)
        const listener = async (e: Event) => {
            if (e.type === 'Botmind_MessageReceivedFromBot') {
                refreshComplaint()
            }
        }
        for (const eventType of eventTypes) {
            document.addEventListener(eventType, listener, false)
        }
        return () => {
            for (const eventType of eventTypes) {
                document.removeEventListener(eventType, listener)
            }
        }
    }, [complaintId, dispatch, userPermissions])

    const currentComplaint = useCurrentComplaint()
    const restaurant = useAppSelector(selectCurrentRestaurant)
    const restaurants: string[] = useAppSelector((state) => selectRestaurantsbyName(state))
    const restaurantsObject = restaurants.map((restaurant, index) => {
        return { key: `restaurant${index}`, value: restaurant }
    })
    const { t } = useTranslation()

    useEffect(() => {
        setTimeout(() => window.botmindWidget?.hide(), 100)
        return () => {
            const bcSid = localStorage.getItem('bc-sid')
            const id = bcSid && JSON.parse(bcSid)?.id
            if (id) {
                window.botmindWidget?.loadSession(id, {
                    startOpen: true,
                    customData: [
                        { key: 'restaurantsList', value: restaurants },
                        { key: 'restaurantsCount', value: restaurants?.length },
                        { key: 'activeRestaurant', value: restaurant?.name },
                        ...restaurantsObject,
                    ],
                })
            }
        }
    }, [restaurants]) // eslint-disable-line react-hooks/exhaustive-deps

    const enabledFranchisedReclamation = useFeatureToggle([FEATURE_TOGGLE_CODE.FRANCHISED_RECLAMATION])

    useEffect(() => {
        ;(async () => {
            if (hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)) {
                let complaint: ComplaintDTO | void = undefined
                try {
                    // @ts-ignore
                    complaint = await dispatch(fetchComplaint(complaintId))
                } catch (error) {
                    setError(error)
                }
                if (
                    window.botmindWidget &&
                    complaint?.chatId &&
                    (!enabledFranchisedReclamation || complaint.complaintType === ComplaintType.DELIVERY)
                ) {
                    try {
                        await window.botmindWidget.loadSession(complaint.chatId, {
                            startOpen: true,
                            customData: chatCustomData(complaint, restaurant),
                        })
                        window.botmindWidget?.show()
                    } catch (error) {
                        console.error(error)
                    }
                }
                if (complaint && !!complaint.endDate && new Date(complaint.endDate) <= new Date()) {
                    setDisabled(true)
                }
                setError(null)
            }
        })()

        return () => {
            dispatch(setCurrentComplaint(undefined))
        }
    }, [dispatch, complaintId]) // eslint-disable-line react-hooks/exhaustive-deps

    const updateRequestHandler = () => {
        dispatch(setCurrentComplaint(undefined))
        // @ts-ignore
        dispatch(fetchComplaint(complaintId))
    }

    const complaintsLastReminder = useAppSelector((state) =>
        selectNumberOfComplaintsByComplaintStatus(state, ComplaintStatus.WAITING_RESTAURANT_SECOND_REMINDER),
    )

    const hasLastReminderBanner = complaintsLastReminder > 0

    const goBack = () => {
        navigate(`${t('page.complaints.route')}?status=${getComplaintStatusType(currentComplaint)}`)
    }

    if (!hasPermission(userPermissions, Permissions.SHOW_COMPLAINT)) {
        return <NoPermissions />
    }

    if (error) {
        return <ErrorBlock errorCode={error.response?.status} />
    }

    if (!currentComplaint) {
        return <ClipLoader size={70} color={colors.orange} loading={true} />
    }

    const { endDate, refundType, status: complaintStatus, complaintNumber, complaintType, inCharge } = currentComplaint

    const complaintStatusLabeled = t(
        inCharge === Responsibility.DELIVERY
            ? `complaints.status.${complaintStatus}_${Responsibility.DELIVERY}`
            : `complaints.status.${complaintStatus}`,
        {
            count: refundType ? isMoneyOrCrown(refundType) : 0, // differentiate Money and Crown status
        },
    )

    return (
        <StyledContainer>
            <TutorialBanner />
            <StyledCardContainer hasBanner={hasLastReminderBanner}>
                <StyledHeader>
                    <StyledTitle>
                        <StyledReturnButton onClick={goBack}>
                            <Chevron color={colors.orange} />
                        </StyledReturnButton>
                        <H2 color={colors.brown}>{t('complaints.title', { id: complaintNumber })}</H2>
                        {!!endDate && complaintStatus && refundType && (
                            <StyledStatusContainer>
                                <StatusRender
                                    status={complaintStatus}
                                    statusLabel={complaintStatusLabeled}
                                    refundType={refundType}
                                    endDate={endDate}
                                    complaint={currentComplaint}
                                />
                            </StyledStatusContainer>
                        )}
                    </StyledTitle>

                    <StyledButtonContainer>
                        <ButtonsRender
                            currentComplaint={currentComplaint}
                            disabled={pending || disabled}
                            setPending={setPending}
                        />
                    </StyledButtonContainer>
                </StyledHeader>
                <StyledContent>
                    {complaintType === ComplaintType.DELIVERY && (
                        <DeliveryComplaintDetail complaint={currentComplaint} />
                    )}
                    {enabledFranchisedReclamation && complaintType === ComplaintType.RESTAURANT && (
                        <RestaurantComplaintDetail complaint={currentComplaint} updateRequest={updateRequestHandler} />
                    )}
                    {enabledFranchisedReclamation && complaintType === ComplaintType.CLICK_AND_COLLECT && (
                        <ClickAndCollectComplaintDetail
                            complaint={currentComplaint}
                            updateRequest={updateRequestHandler}
                        />
                    )}
                </StyledContent>
            </StyledCardContainer>
        </StyledContainer>
    )
}
