import { QueryOptions, useQuery } from 'react-query'
import { EmbeddedTokenResponse } from 'types/api'

import { services } from '../../httpServices/services'

export const POWERBI_KEYS = {
    GET_TOKEN: 'getToken',
}

export const useGetPowerBiData = (
    frNumber: string,
    enabled: boolean,
    options?: QueryOptions<EmbeddedTokenResponse>,
) => {
    return useQuery({
        queryKey: [POWERBI_KEYS.GET_TOKEN, frNumber],
        queryFn: () => services.restaurant.powerBi.getToken(frNumber),
        enabled: enabled,
        ...options,
    })
}
