import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { H2, H4 } from 'styles/typography'
import { StatusType } from 'utils/enums'

//TODO: Add breakpoints
export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-shadow:
        0px 2px 24px ${colors.shadowDark},
        inset 0px 4px 0px ${colors.orange};
    background-color: ${colors.white};
    border-radius: 12px 12px 0px 0px;
    box-sizing: border-box;
    @media (orientation: landscape) {
        padding: 40px 150px 0 150px;
    }
    @media (orientation: portrait) {
        padding: 40px 90px 0 90px;
    }
`

export const StyledHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledTimeContainer = styled.div<{ status: StatusType; delivery: boolean }>`
    border: solid 5px transparent;
    border-radius: 50%;
    background-image: linear-gradient(white, white),
        ${(props) =>
            props.status === StatusType.ERROR
                ? `linear-gradient(to right, ${colors.red} 50%, ${colors.red} 50%)`
                : props.delivery
                  ? `linear-gradient(to right, ${colors.green} 50%, ${colors.greyLight} 50%)`
                  : `linear-gradient(to right, ${colors.green} 50%, ${colors.green} 50%)`};
    background-origin: border-box;
    background-clip: content-box, border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
`

export const StyledTitle = styled.div`
    display: flex;
`

export const StyledPickupTypeContainer = styled.div`
    padding: 10px 55px 12px 15px;
    border: 2px solid ${colors.brown};
    box-sizing: border-box;
    border-radius: 12px;
    height: fit-content;
    margin-top: 15px;
`

export const StyledButtonBottomContainer = styled.div<{ additionalMarginBottom?: boolean }>`
    position: absolute;
    width: 100%;
    height: 10%;
    left: 0;
    bottom: ${(props) => (props.additionalMarginBottom ? `80px` : `0px`)};
    background: ${colors.white};
    box-shadow:
        0px -1px 0px ${colors.greyLight},
        0px -2px 16px ${colors.shadow};
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const StyledH2 = styled(H2)`
    color: ${colors.brown};
    margin: 0;
`

export const StyledH4Info = styled(H4)`
    font-family: ${fonts.flameSans};
    margin: -1px 0 0 10px;
`

export const StyledPriceText = styled(H4)<{ bold?: boolean; margin?: boolean }>`
    font-family: ${(props) => (props.bold ? fonts.flame : fonts.flameSans)};
    ${(props) => props.margin && 'margin-right: 35px'};
`

export const StyledTitleText = styled.div`
    margin: 12px 25px;
`

export const StyledContent = styled.div<{ status: StatusType; additionalMarginBottom?: boolean }>`
    display: flex;
    width: 100%;
    overflow-y: auto;
    margin-top: 25px;
    height: ${(props) => (props.status === StatusType.ERROR ? 'calc(100% - 180px)' : '100%')};
    padding-bottom: ${(props) => (props.additionalMarginBottom ? `130px` : `50px`)};
`

export const StyledOrderContainer = styled.div`
    width: 60%;
    height: fit-content;
    display: flex;
    flex-direction: column;
`

export const StyledInfoContainer = styled.div`
    background: ${colors.grey};
    border-radius: 5px;
    padding: 16px;
    margin-left: 24px;
    box-sizing: border-box;
    width: 40%;
    height: fit-content;
`

export const StyledCardInfo = styled.div<{ vip?: boolean }>`
    background: ${colors.white};
    border-radius: 12px;
    width: 100%;
    margin: 16px 0;
    padding: 12px 16px;
    box-sizing: border-box;
    ${(props) => props.vip && `border: 2px solid ${colors.orange};`}
`

export const StyledInfoLine = styled.div<{ warning?: boolean }>`
    display: flex;
    padding: 8px 0;
    ${(props) =>
        props.warning &&
        `
    background: ${colors.orangeLight};
    border-radius: 5px;
    padding: 8px;
    `}
`

export const StyledCenter = styled.div`
    margin: 0 auto;
    margin-top: 200px;
`

export const StyledTotalContainer = styled.div`
    margin-top: 40px;
`

export const StyledSeparator = styled.div`
    height: 1px;
    background-color: ${colors.shadowDark};
`

export const StyledTotal = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 2px 0 2px auto;
    padding: 2px;
`

export const StyledTime = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    & > h2 {
        margin-top: -7px;
    }
`

export const StyledIframe = styled.iframe`
    width: 100%;
    height: 250px;
    border: solid ${colors.orange};
    border-radius: 1em;
`
