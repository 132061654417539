import React from 'react'
import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { beige } = colors

export const LogoBKIcon = (props: IconProps) => {
    const { color = beige, size = 28, ...otherProps } = props
    return (
        <svg width={size} height={size + 2} viewBox="0 0 28 30" fill="none" {...otherProps}>
            <path
                d="M11.2911 8.71892C8.67718 8.71892 6.06326 8.73312 3.46354 8.71892C2.65379 8.71892 1.84404 8.56265 1.0485 8.47741C0.679145 8.4348 0.451847 8.09385 0.508671 7.61084C0.650732 6.50277 0.991678 5.45152 1.7446 4.57074C3.54878 2.46824 5.87858 1.27492 8.54932 0.649855C10.7086 0.152641 12.8964 -0.103069 15.0983 0.0389922C17.3429 0.181053 19.5875 0.436763 21.6758 1.30334C22.8833 1.81475 24.0624 2.45403 25.1136 3.22116C26.4206 4.17297 27.2303 5.56516 27.6991 7.11363C27.9975 8.12226 27.4576 8.6763 26.4206 8.66209C24.2044 8.63368 21.9883 8.66209 19.7721 8.66209C18.1384 8.66209 16.5189 8.70471 14.8852 8.71892C13.6919 8.71892 12.4986 8.71892 11.3053 8.71892H11.2911Z"
                fill={color}
            />
            <path
                d="M15.9647 30.0001C13.7059 29.9433 11.2482 29.9433 8.79059 29.8154C7.14268 29.7302 5.48057 29.5597 3.94631 28.892C3.15077 28.5511 2.39784 28.1249 1.68754 27.6277C1.1193 27.2441 0.749937 26.6475 0.622082 25.9372C0.551052 25.5394 0.678907 25.2837 1.09088 25.17C1.30397 25.1132 1.53127 25.099 1.75857 25.099C3.12236 25.1132 4.48614 25.17 5.84993 25.17C7.99505 25.17 10.1402 25.1132 12.2995 25.099C15.78 25.0848 19.2605 25.0706 22.7552 25.0564C24.1048 25.0564 25.4685 25.0848 26.8181 25.099C27.8552 25.1132 27.9688 25.3831 27.6137 26.3776C27.358 27.1163 26.8181 27.6987 26.1504 28.1249C24.7298 29.0199 23.1387 29.4887 21.4766 29.6308C19.7151 29.787 17.9535 29.8581 15.9931 29.9717L15.9647 30.0001Z"
                fill={color}
            />
            <path
                d="M15.3397 20.624C15.3397 21.3343 15.3681 22.0446 15.3255 22.7549C15.2971 23.1101 15.1976 23.5079 14.9988 23.792C14.6152 24.3176 14.047 24.4739 13.3935 24.3034C12.7258 24.1187 12.6121 23.5789 12.5837 23.0249C12.5269 22.2151 12.5411 21.4054 12.5411 20.5956C12.5411 19.5586 12.5127 18.5215 12.5127 17.4845C12.5127 16.9162 12.74 16.4048 13.1662 16.0355C13.7202 15.524 14.5442 15.6803 15.0982 16.419C15.5102 16.9731 15.8369 17.5697 16.1779 18.1664C16.4052 18.5641 16.5898 18.9903 16.8029 19.4165L16.9592 19.3881C16.9592 18.7204 16.9308 18.0669 16.9592 17.3992C16.9876 16.7884 17.2291 16.2201 17.7547 15.9076C18.6213 15.3678 19.701 16.0212 19.7152 17.1293C19.7578 19.104 19.7436 21.0786 19.7294 23.0533C19.7294 23.6925 19.2322 23.9909 18.735 24.2324C18.2378 24.4739 17.84 24.275 17.4422 23.934C16.7319 23.2948 16.462 22.414 16.0074 21.6327C15.8085 21.2917 15.6238 20.9508 15.4392 20.6098H15.3255L15.3397 20.624Z"
                fill={color}
            />
            <path
                d="M3.69114 18.3225C4.01788 17.8963 4.34462 17.4701 4.68556 17.0297C4.91286 16.7456 5.11175 16.433 5.36746 16.1773C5.82205 15.7085 6.54656 15.6091 7.05798 15.9358C7.52678 16.2484 7.8109 17.0581 7.62622 17.6122C7.42734 18.2514 7.00116 18.7344 6.51815 19.1606C6.46133 19.2174 6.3903 19.2743 6.33347 19.3311C6.03041 19.6057 6.0162 19.8899 6.29085 20.1835C6.67442 20.6096 7.07219 21.0216 7.44155 21.462C8.12344 22.2718 8.16606 23.4509 7.07219 24.0759C6.6318 24.3316 6.14879 24.3743 5.77943 24.0475C5.28222 23.6071 4.84183 23.0957 4.42985 22.5701C4.11732 22.1581 3.87582 21.6893 3.62011 21.2347L3.47804 21.2773C3.47804 21.7177 3.47804 22.1581 3.47804 22.5985C3.47804 23.5645 2.6825 24.3458 1.81593 24.2464C1.06301 24.1612 0.523177 23.4935 0.537383 22.5843C0.551589 21.1495 0.608414 19.7289 0.651032 18.294C0.651032 17.811 0.651032 17.3138 0.69365 16.8308C0.736268 16.3336 1.06301 15.9927 1.5176 15.879C1.9722 15.7654 2.441 15.7085 2.86718 16.0779C3.39281 16.5183 3.59169 17.0865 3.57749 17.7542C3.57749 17.9105 3.57749 18.081 3.57749 18.2372L3.70534 18.294L3.69114 18.3225Z"
                fill={color}
            />
            <path
                d="M24.1756 24.261C22.1725 24.3889 20.5814 22.6416 20.4394 20.8516C20.3115 19.2179 20.5104 17.5558 22.0447 16.5045C22.5419 16.1636 23.167 15.9079 23.7636 15.8084C24.6444 15.6664 25.5536 15.7942 26.3917 16.121C27.2441 16.4477 27.6277 17.229 27.3009 17.982C27.0594 18.536 26.548 18.7491 25.9513 18.5786C25.5394 18.465 25.1132 18.3371 24.687 18.3087C23.9199 18.2661 23.2664 18.7491 23.0817 19.5162C22.826 20.6101 23.0675 21.434 24.0761 21.775C24.3176 21.8602 24.6018 21.8744 24.8575 21.846C24.9853 21.846 25.1842 21.7466 25.2126 21.5619C25.2126 21.363 24.9569 21.3488 24.8149 21.3488C24.3603 21.3488 24.0761 21.0931 23.8631 20.7521C23.5079 20.1839 23.8488 19.4026 24.5307 19.2321C25.2268 19.0616 25.9371 19.1184 26.5906 19.3315C26.9316 19.4452 27.2725 19.7861 27.4572 20.1271C28.2669 21.6471 27.2725 23.6786 25.582 24.0337C25.1132 24.1332 24.6444 24.1758 24.1756 24.261Z"
                fill={color}
            />
            <path
                d="M8.52096 19.914C8.52096 19.0758 8.52096 18.2377 8.52096 17.3853C8.52096 16.4903 9.06079 15.9079 9.92737 15.7658C10.6377 15.6522 11.5042 16.4051 11.5469 17.2574C11.6037 18.3229 11.5895 19.3884 11.5895 20.4538C11.5895 20.9794 11.5895 21.5193 11.5469 22.0449C11.5184 22.4427 11.4474 22.8404 11.348 23.2382C11.1065 24.1616 10.1263 24.5025 9.3023 24.048C8.77667 23.7638 8.53517 23.1672 8.52096 22.5421C8.50676 21.6613 8.52096 20.7806 8.52096 19.8998V19.914Z"
                fill={color}
            />
            <path
                d="M17.371 13.4643C17.229 13.3933 17.0585 13.3507 16.9449 13.237C16.7602 13.0666 16.4903 12.8535 16.4903 12.6688C16.4903 12.3989 16.6749 12.0579 16.888 11.9017C17.3284 11.5749 18.4649 11.7312 18.8627 12.1148C19.3031 12.5409 19.1752 13.095 19.1042 13.578C18.9479 14.5866 17.9961 15.3253 16.9733 15.2401C15.8794 15.1549 14.8281 14.885 14.473 13.6916C14.1036 12.4841 13.99 11.2624 14.9844 10.2254C15.922 9.25934 17.5415 9.24513 18.5644 9.84179C18.9763 10.0833 19.0758 10.4953 18.8769 10.893C18.6638 11.3192 18.2802 11.4897 17.783 11.3192C17.4421 11.1914 17.0869 11.0209 16.7176 11.2198C16.6749 11.2482 16.6039 11.2908 16.5755 11.3192C16.2061 11.8022 16.1635 12.3705 16.1777 12.9529C16.1777 13.3365 16.5187 13.4785 16.817 13.6064C16.9733 13.6632 17.1722 13.6064 17.3426 13.6064V13.4359L17.371 13.4643Z"
                fill={color}
            />
            <path
                d="M11.7314 13.3506C11.7314 13.6774 11.7314 14.0041 11.7314 14.3451C11.7314 14.9417 11.4331 15.3395 10.9643 15.3679C10.396 15.3963 9.98403 15.0412 9.96983 14.4161C9.94141 13.0523 9.94141 11.7027 9.95562 10.339C9.95562 9.88436 10.2824 9.55762 10.737 9.48659C11.6319 9.34453 12.4985 9.5008 13.3083 9.89857C13.8765 10.1685 14.2175 11.2339 13.9333 11.8022C13.7629 12.1573 13.5498 12.4841 13.3509 12.825C13.6066 13.2228 13.8623 13.6206 14.1038 14.0325C14.3737 14.4871 14.3169 14.8991 14.0044 15.1832C13.6918 15.4674 13.2798 15.4816 12.8395 15.1974C12.4701 14.9559 12.2428 14.615 12.1434 14.1888C12.0723 13.9047 11.9729 13.6206 11.8734 13.3364L11.7172 13.3791L11.7314 13.3506ZM11.6035 11.1345C11.6035 11.3618 11.3904 11.8732 11.9729 11.9016C12.257 11.9158 12.3991 11.7312 12.3991 11.4612C12.3991 11.1345 12.2144 11.0493 11.6035 11.1345Z"
                fill={color}
            />
            <path
                d="M0.636042 10.339C0.664454 9.91278 0.920164 9.64286 1.30373 9.60025C1.91459 9.54342 2.55386 9.51501 3.16473 9.55763C4.08812 9.60025 4.69899 10.7367 4.30121 11.6175C4.15915 11.93 4.23018 12.1289 4.38645 12.4415C4.98311 13.5922 4.89787 14.7713 3.43464 15.169C2.80958 15.3395 2.12768 15.3395 1.48841 15.3111C0.849134 15.2827 0.565012 14.9133 0.522393 14.203C0.493981 13.6774 0.522393 13.1376 0.522393 12.6119C0.550805 11.8448 0.579218 11.1061 0.636042 10.3532V10.339ZM2.1703 12.9245C2.1703 13.1376 2.1703 13.2654 2.1703 13.3791C2.14189 13.6064 2.12768 13.8053 2.42601 13.8479C2.72434 13.8905 2.95164 13.791 3.00846 13.4643C3.06528 13.2086 2.98005 13.0239 2.71013 12.9671C2.53966 12.9387 2.36919 12.9387 2.18451 12.9245H2.1703ZM2.22712 11.7312C2.56807 11.7312 2.8664 11.6885 2.85219 11.2908C2.83799 10.8078 2.44022 10.9356 2.11348 10.8504L2.22712 11.7312Z"
                fill={color}
            />
            <path
                d="M26.7754 12.8249C26.9885 13.1232 27.23 13.3931 27.3863 13.6914C27.5425 14.004 27.7414 14.3733 27.6988 14.6858C27.6136 15.3251 26.818 15.5382 26.3208 15.0268C26.0225 14.7285 25.852 14.2739 25.6531 13.8903C25.5537 13.7198 25.4969 13.521 25.4258 13.3363L25.298 13.3647C25.298 13.6204 25.3406 13.8903 25.298 14.1318C25.2411 14.4159 25.1559 14.7001 24.9996 14.9416C24.8008 15.2683 24.4456 15.3393 24.1047 15.2257C23.7353 15.0978 23.4938 14.7995 23.4796 14.4017C23.4512 13.3931 23.4512 12.3845 23.4512 11.3758C23.4512 10.9923 23.4512 10.5945 23.4796 10.2109C23.4938 9.81316 23.8489 9.45801 24.3462 9.45801C25.0423 9.47221 25.7384 9.55745 26.4202 9.6711C27.1874 9.79895 27.4147 10.4382 27.5567 11.0775C27.6988 11.7168 27.4573 12.2566 26.9317 12.6686C26.9033 12.697 26.8748 12.7254 26.7754 12.8106V12.8249ZM25.1417 11.9867C25.4116 11.9015 25.6815 11.8872 25.7952 11.7452C25.8946 11.6315 25.9088 11.2764 25.8236 11.1769C25.6815 11.0349 25.4116 11.0207 25.1417 10.9354V11.9867Z"
                fill={color}
            />
            <path
                d="M7.68281 10.3674C7.68281 9.89857 8.05217 9.48659 8.49256 9.47239C8.93294 9.45818 9.31651 9.77072 9.35913 10.2537C9.48698 11.5323 9.55801 12.825 9.2881 14.1036C9.20286 14.5156 8.96136 14.7997 8.57779 14.9844C7.83907 15.3537 7.08615 15.4958 6.2764 15.2117C5.58031 14.9701 5.21095 14.4871 5.16833 13.791C5.1115 12.9529 5.13992 12.1147 5.13992 11.2766C5.13992 11.0493 5.13992 10.822 5.13992 10.6089C5.0973 10.1117 5.38142 9.65707 5.86443 9.51501C6.31902 9.37295 6.81624 9.7281 6.85885 10.2679C6.91568 10.9498 6.91568 11.6459 6.9583 12.3278C6.98671 12.6972 7.01512 13.0807 7.07194 13.4501C7.10036 13.6206 7.11456 13.8763 7.38448 13.7768C7.51233 13.7342 7.64019 13.5211 7.65439 13.3791C7.69701 13.0523 7.71122 12.6688 7.71122 12.3278C7.71122 12.1431 7.71122 10.8788 7.71122 10.3958L7.68281 10.3674Z"
                fill={color}
            />
            <path
                d="M21.2636 13.1518V13.6064C21.5193 13.6064 21.7608 13.578 22.0165 13.5638C22.5563 13.5212 22.9967 13.8053 23.082 14.2457C23.1814 14.7287 22.8831 15.1975 22.3148 15.2543C21.6187 15.3253 20.9084 15.2969 20.2265 15.2259C19.772 15.1833 19.5873 14.8849 19.5731 14.3309C19.5447 12.9955 19.5447 11.6744 19.5731 10.339C19.5731 9.92701 19.9282 9.58607 20.3118 9.55766C20.88 9.52924 21.4625 9.51504 22.0307 9.47242C22.5279 9.44401 22.8263 9.72813 23.0394 10.0975C23.2951 10.5379 22.9967 11.0209 22.4569 11.0777C22.1586 11.1061 21.8745 11.1487 21.5761 11.1629C21.4199 11.1629 21.2494 11.305 21.2494 11.4329C21.2636 11.6175 21.4909 11.6033 21.6187 11.646C21.7608 11.6886 21.9313 11.646 22.0733 11.646C22.4995 11.6744 22.8121 11.9585 22.8263 12.3421C22.8547 12.7398 22.5848 13.0239 22.1302 13.0808C21.8602 13.1092 21.5761 13.1234 21.2494 13.1518H21.2636Z"
                fill={color}
            />
        </svg>
    )
}
