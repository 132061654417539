import { BoldCross, CouponIcon, Crown2, MoneyIcon, RestaurantManIcon } from 'assets/icons'
import { colors } from 'styles/colors'
import { RefundType } from 'types/api.enum'

export const RefundTypeIcon = ({ refundType }: { refundType: RefundType | undefined }) => {
    switch (refundType) {
        case RefundType.RESTAURANT_MEETING:
            return <RestaurantManIcon color={colors.brown} size={18} />
        case RefundType.CROWN:
            return <Crown2 color={colors.yellowMelty} size={20} />
        case RefundType.COUPON:
            return <CouponIcon color={colors.yellowMelty} size={20} />
        case RefundType.MONEY:
            return <MoneyIcon color={colors.yellowMelty} size={20} />
        case undefined:
        case null:
            return <BoldCross color={colors.brown} size={16} />
        default:
            return <></>
    }
}
