import { Crown2 } from 'assets/icons'
import { Modal } from 'components/modal/Modal'
import { useAppDispatch } from 'hooks/useRedux'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { fetchCouponsList } from 'redux/complaint/thunks'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { SPACING } from 'styles/tokens'
import { BigTitle } from 'styles/typography'
import { ComplaintDTO, ComplaintPromotionDTO, StoreLocRestaurant } from 'types/api'
import { RefundType } from 'types/api.enum'

import { useFeatureToggle } from '../../../../hooks/useFeatureToggle'
import { FEATURE_TOGGLE_CODE } from '../../../../utils/enums'
import { BeginComplaintProcessModalContent } from './BeginProcessComplaintModalContent'
import { ComplaintMessageModalContent } from './ComplaintMessageModalContent'
import { CouponType, ListCouponModalContent } from './ListCouponModal'
import { RefundChoiceModalContent } from './RefundChoiceModal'

type ButtonsRenderProps = {
    handleClose: () => void
    complaint: ComplaintDTO
    currentRestaurant?: StoreLocRestaurant
}

enum AnswerProcessStep {
    BEGIN_COMPLAINT = 'BEGIN_COMPLAINT',
    CUSTOMER_MESSAGE = 'CUSTOMER_MESSAGE',
    REFUND_CHOICE = 'REFUND_CHOICE',
    LIST_PROMOTION = 'LIST_PROMOTION',
}

const refundCrownValues = [30, 60, 80, 120, 140, 200]

const RefundCrownAmountIcon = ({ amount }: { amount: number }) => {
    return (
        <StyledIconContainer>
            <StyledBigTitle color={colors.white}>{amount}</StyledBigTitle>
            <Crown2 color={colors.white} size={32} />
        </StyledIconContainer>
    )
}

export const RestaurantComplaintAnswerProcess = ({ handleClose, complaint, currentRestaurant }: ButtonsRenderProps) => {
    const { t } = useTranslation()
    const [currentStep, setCurrentStep] = useState<AnswerProcessStep>(AnswerProcessStep.BEGIN_COMPLAINT)
    const dispatch = useAppDispatch()
    const { complaintId = '' } = useParams()
    const [promotions, setPromotions] = useState<ComplaintPromotionDTO[]>([])
    const [selectedRefundType, setSelectedRefundType] = useState<RefundType>()
    const [selectedPromotion, setSelectedPromotion] = useState<CouponType>()
    const STEPS = [
        AnswerProcessStep.BEGIN_COMPLAINT,
        AnswerProcessStep.REFUND_CHOICE,
        AnswerProcessStep.LIST_PROMOTION,
        AnswerProcessStep.CUSTOMER_MESSAGE,
    ]

    useEffect(() => {
        if (currentStep === AnswerProcessStep.LIST_PROMOTION && selectedRefundType === RefundType.COUPON) {
            const fetchCoupons = async () => {
                const response = await dispatch(fetchCouponsList(complaintId))
                const { promotions } = response
                setPromotions(promotions)
            }
            fetchCoupons()
        }
    }, [complaintId, currentStep, dispatch, selectedRefundType])

    const createCrownCoupon = (value: number) => {
        return {
            promotionId: `${value}`,
            label: t('complaints.listCouponModal.crown', {
                count: value,
            }),
            icon: <RefundCrownAmountIcon amount={value} />,
            description: t('complaints.listCouponModal.crownDescription'),
            refundType: RefundType.CROWN,
        }
    }

    const displayedPromotion =
        selectedRefundType === RefundType.CROWN
            ? refundCrownValues.map((value) => createCrownCoupon(value))
            : promotions.map((promotion) => {
                  return {
                      ...promotion,
                      promotionId: promotion.promotionId,
                      description: t('complaints.listCouponModal.couponDescription'),
                      refundType: RefundType.COUPON,
                      couponType: promotion.type,
                  }
              })

    const handleModalClose = () => {
        handleClose()
        setCurrentStep(AnswerProcessStep.BEGIN_COMPLAINT)
    }

    const handleNext = () => {
        const index = STEPS.indexOf(currentStep)
        setCurrentStep(STEPS[index + 1])
    }

    const handleBack = () => {
        const index = STEPS.indexOf(currentStep)
        setCurrentStep(STEPS[index - 1])

        if (currentStep === AnswerProcessStep.CUSTOMER_MESSAGE) {
            setCurrentStep(AnswerProcessStep.REFUND_CHOICE)
        }
    }

    const selectRefundTypeHandler = (currentRefundType: RefundType) => {
        setSelectedRefundType(currentRefundType)

        if (currentRefundType === RefundType.RESTAURANT_MEETING) {
            setSelectedPromotion({ refundType: RefundType.RESTAURANT_MEETING })
            setCurrentStep(AnswerProcessStep.CUSTOMER_MESSAGE)
        } else {
            handleNext()
        }
    }

    const handlePromotionSubmit = (id: string) => {
        const selectedCoupon = (displayedPromotion as CouponType[]).find((value) => id === value.promotionId)
        setSelectedPromotion(selectedCoupon)
        setCurrentStep(AnswerProcessStep.CUSTOMER_MESSAGE)
    }

    const enabledPromotionDouble = useFeatureToggle([FEATURE_TOGGLE_CODE.COMPLAINT_PROMOTION_DOUBLE])

    const RestaurantComplaintsStepModal = ({ step }: { step: AnswerProcessStep }) => {
        switch (step) {
            case AnswerProcessStep.BEGIN_COMPLAINT:
                return <BeginComplaintProcessModalContent complaint={complaint} onConfirm={handleNext} />
            case AnswerProcessStep.CUSTOMER_MESSAGE:
                return (
                    <ComplaintMessageModalContent
                        onCompensation={handleBack}
                        selectedPromotion={selectedPromotion}
                        onClose={handleModalClose}
                        currentRestaurant={currentRestaurant}
                    />
                )
            case AnswerProcessStep.REFUND_CHOICE:
                return <RefundChoiceModalContent onBackClick={handleBack} onConfirm={selectRefundTypeHandler} />
            case AnswerProcessStep.LIST_PROMOTION:
                return (
                    <>
                        {selectedRefundType && selectedRefundType !== RefundType.RESTAURANT_MEETING && (
                            <ListCouponModalContent
                                onBackClick={handleBack}
                                onConfirm={handlePromotionSubmit}
                                coupons={displayedPromotion}
                                refundType={selectedRefundType}
                                enabledPromotionDouble={enabledPromotionDouble}
                            />
                        )}
                    </>
                )
            default:
                return <></>
        }
    }

    return (
        <Modal handleClose={handleModalClose} closeIconColor={colors.brown}>
            <RestaurantComplaintsStepModal step={currentStep} />
        </Modal>
    )
}

const StyledBigTitle = styled(BigTitle)`
    font-size: 48px;
    line-height: 64px;
`

const StyledIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: ${SPACING.S};
    height: 100%;
`
