import { BoldCross, CouponIcon, Crown2, RestaurantManIcon } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { StyledTypeContainer, StyledTypeIconContainer } from 'screens/complaint-detail/style'
import { colors } from 'styles/colors'
import { H4 } from 'styles/typography'
import { ComplaintDTO } from 'types/api'
import { RefundType } from 'types/api.enum'

export const RefundInfoCard = ({ complaint }: { complaint: ComplaintDTO }) => {
    const { t } = useTranslation()
    switch (complaint.refundType) {
        case RefundType.RESTAURANT_MEETING:
            return (
                <StyledTypeContainer>
                    <StyledTypeIconContainer>
                        <RestaurantManIcon color={colors.brown} size={22} />
                    </StyledTypeIconContainer>
                    <H4 color={colors.brown}>{t('complaints.refund.restaurant')} </H4>
                </StyledTypeContainer>
            )
        case RefundType.CROWN:
            return (
                <StyledTypeContainer>
                    <StyledTypeIconContainer>
                        <Crown2 color={colors.yellowMelty} size={22} />
                    </StyledTypeIconContainer>
                    <H4 color={colors.brown}>
                        {t('complaints.refund.crown', {
                            crownAmount: complaint.refundAmountCrown,
                        })}
                    </H4>
                </StyledTypeContainer>
            )
        case RefundType.COUPON:
            return (
                <StyledTypeContainer>
                    <StyledTypeIconContainer>
                        <CouponIcon color={colors.yellowMelty} size={22} />
                    </StyledTypeIconContainer>

                    <H4 color={colors.brown}>
                        {complaint.couponPromotionLabel
                            ? t('complaints.refund.coupon', {
                                  label: complaint.couponPromotionLabel,
                                  interpolation: { escapeValue: false },
                              })
                            : t('complaints.emptyField')}
                    </H4>
                </StyledTypeContainer>
            )
        case undefined:
        case null:
            return (
                <StyledTypeContainer>
                    <StyledTypeIconContainer>
                        <BoldCross color={colors.brown} size={20} />
                    </StyledTypeIconContainer>
                    <H4 color={colors.brown}>{t('complaints.refund.noRefund')}</H4>
                </StyledTypeContainer>
            )
        default:
            return <></>
    }
}
