import { Exclamation } from 'assets/icons'
import { Button } from 'components/button/Button'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { SPACING } from 'styles/tokens'
import { BigText } from 'styles/typography'

import { Banner } from './Banner'

interface BannerProps {
    onClick: () => void
}

export const ComplaintBanner = ({ onClick }: BannerProps) => {
    const { t } = useTranslation()

    return (
        <Banner
            position={'fixed'}
            sx={{
                bottom: 0,
                top: 'auto',
                paddingRight: '60px',
                borderRadius: `${SPACING.XS} ${SPACING.XS} 0 0`,
            }}
        >
            <StyledBannerContainer>
                <StyledContentContainer>
                    <Exclamation size={48} color={colors.white} />
                    <BigText color={colors.white} font={fonts.flame}>
                        {t('complaints.banner.description')}
                    </BigText>
                </StyledContentContainer>
                <Button
                    color={colors.white}
                    text={t('complaints.banner.button')}
                    textColor={colors.black}
                    onClick={onClick}
                />
            </StyledBannerContainer>
        </Banner>
    )
}

const StyledBannerContainer = styled.div`
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    width: 100%;
`

const StyledContentContainer = styled.div`
    display: flex;
    gap: ${SPACING.L};
    align-items: center;
`
