import { BackButton } from 'components/back-button/BackButton'
import { ErrorBlock } from 'components/error-block/ErrorBlock'
import { useAppSelector } from 'hooks/useRedux'
import { models } from 'powerbi-client'
import { PowerBIEmbed } from 'powerbi-client-react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useGetPowerBiData } from 'redux/powerbi/powerbi'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { BORDER, SPACING } from 'styles/tokens'
import { H2 } from 'styles/typography'

export const PowerBI = () => {
    const selectedFrNumber: string = useAppSelector((state) => state.restaurant.selectedFrNumber)
    const [enabled, setEnabled] = useState(false)
    const navigate = useNavigate()
    const { t } = useTranslation()

    const { data: powerBiData, isLoading, isError } = useGetPowerBiData(selectedFrNumber, enabled)

    const embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${powerBiData?.reportId}&groupId=${powerBiData?.datasetId}`

    useEffect(() => {
        if (powerBiData?.expiration) {
            const expiration = new Date(powerBiData.expiration)
            const isExpired = expiration < new Date()
            if (isExpired) {
                setEnabled(true)
            } else {
                setEnabled(false)
            }
        } else {
            setEnabled(true)
        }
    }, [powerBiData?.expiration])

    return (
        <StyledMainContainer>
            <StyledTitleButtonContainer>
                <BackButton
                    onClick={() => {
                        navigate(t('page.complaints.route'))
                    }}
                />
                <StyledTitle>
                    <StyledH2>{t('statistics.title')}</StyledH2>
                </StyledTitle>
            </StyledTitleButtonContainer>

            {isLoading ? (
                <StyledLoaderContainer>
                    <ClipLoader size={70} color={colors.orange} loading={true} />
                </StyledLoaderContainer>
            ) : isError ? (
                <ErrorBlock />
            ) : (
                powerBiData && (
                    <StyledPowerBiContainer>
                        <PowerBIEmbed
                            embedConfig={{
                                type: 'report',
                                id: powerBiData.reportId,
                                embedUrl: embedUrl,
                                accessToken: powerBiData.token,
                                tokenType: models.TokenType.Embed,
                                settings: {
                                    layoutType: models.LayoutType.Custom,
                                    panes: {
                                        filters: {
                                            expanded: false,
                                            visible: false,
                                        },
                                        pageNavigation: {
                                            visible: false,
                                        },
                                    },
                                },
                            }}
                            cssClassName={'report-style-class'}
                            getEmbeddedComponent={(embeddedReport) => {
                                console.log('Report loaded:', embeddedReport)
                            }}
                        />
                    </StyledPowerBiContainer>
                )
            )}
        </StyledMainContainer>
    )
}

const StyledMainContainer = styled.div`
    box-sizing: border-box;
    height: 80vh;
    width: 100%;
`

const StyledPowerBiContainer = styled.div`
    height: calc(100% - 67px);
    width: 100%;

    & .report-style-class {
        height: 100%;
        width: 100%;
    }

    & iframe {
        border: 'none';
        border-radius: ${BORDER.RADIUS.XL};
        border: ${BORDER.WIDTH.XS} solid ${colors.greyExtraLight};
        padding: ${SPACING.M};
        box-sizing: border-box;
        background-color: ${colors.white};
    }
`

const StyledTitleButtonContainer = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${SPACING.M};
`

const StyledH2 = styled(H2)`
    margin-bottom: ${SPACING.L};
    color: ${colors.brown};
`

const StyledTitle = styled.div`
    margin-top: ${SPACING.XS};
`

const StyledLoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 67px);
`
