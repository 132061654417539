import { colors } from 'styles/colors'
import { IconProps } from 'types/icons'

const { brownDark } = colors

export const ComplaintIcon = (props: IconProps) => {
    const { color = brownDark, size = 20, ...otherProps } = props
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" {...otherProps}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 11.9999C16.7427 11.9331 16.4695 11.9715 16.2405 12.1066C16.0116 12.2417 15.8459 12.4624 15.78 12.7199C14.9934 15.8232 12.2015 17.9974 9.00001 17.9999H3.41001L4.05001 17.3699C4.43774 16.9798 4.43774 16.3499 4.05001 15.9599C2.26691 14.1839 1.57439 11.5874 2.23622 9.15937C2.89804 6.7313 4.81233 4.84538 7.25001 4.21988C7.78573 4.08181 8.10809 3.53559 7.97001 2.99988C7.83194 2.46416 7.28573 2.14181 6.75001 2.27988C3.79779 3.04962 1.43654 5.26259 0.477207 8.15876C-0.482125 11.0549 0.0911003 14.2399 2.00001 16.6199L0.290014 18.2899C0.00626959 18.5774 -0.0765826 19.0075 0.0800141 19.3799C0.233418 19.7533 0.596312 19.9979 1.00001 19.9999H9.00001C13.1062 20.0025 16.6935 17.2256 17.72 13.2499C17.796 12.9885 17.7622 12.7074 17.6263 12.4715C17.4905 12.2356 17.2643 12.0653 17 11.9999ZM18.54 1.45974C16.8556 -0.228926 14.213 -0.492746 12.228 0.829596C10.243 2.15194 9.46833 4.69223 10.3777 6.89718C11.2871 9.10214 13.6273 10.3578 15.9674 9.89634C18.3074 9.4349 19.9958 7.38486 20 4.99974C20.0024 3.67275 19.4772 2.39925 18.54 1.45974ZM12 4.99959C12 3.34274 13.3432 1.99959 15 1.99959C15.447 2.00224 15.8877 2.10474 16.29 2.29959L12.29 6.29959C12.0972 5.89346 11.9981 5.44918 12 4.99959ZM17.12 7.11971C16.2091 7.98559 14.8635 8.21118 13.72 7.68971L17.72 3.68971C17.9109 4.09969 18.0066 4.54752 18 4.99971C17.9993 5.7951 17.6828 6.55765 17.12 7.11971Z"
                fill={color}
            />
        </svg>
    )
}
