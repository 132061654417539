import { Tab, Tabs } from '@mui/material'
import { ArrowLeft, Cross } from 'assets/icons'
import { Banner } from 'components/banner/Banner'
import { Button } from 'components/button/Button'
import { Coupon } from 'components/coupon/Coupon'
import { useOpenOnceBanner } from 'hooks/useOpenOnceBanner'
import { ReactNode, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'
import { H2, Text } from 'styles/typography'
import { PromotionType, RefundType } from 'types/api.enum'
import { LocalStorageKey } from 'utils/enums'

export type CouponType = {
    promotionId?: string
    label?: string
    imageUrl?: string
    icon?: ReactNode
    description?: string
    refundType: RefundType
    couponType?: PromotionType
}

type ListCouponModalContentProps = {
    coupons: CouponType[]
    onBackClick: () => void
    onConfirm: (id: string) => void
    refundType: string
    enabledPromotionDouble?: boolean
}

export const ListCouponModalContent = ({
    onBackClick,
    onConfirm,
    coupons,
    refundType,
    enabledPromotionDouble = false,
}: ListCouponModalContentProps) => {
    const { t } = useTranslation()
    const [isSelectedPromotion, setIsSelectedPromotion] = useState<string>('')

    const { handleClick, displayBanner } = useOpenOnceBanner(LocalStorageKey.HAS_SEEN_COUPON_BANNER)

    const selectPromotionClickHandler = (id: string) => {
        setIsSelectedPromotion(id)
    }

    const [tabsValue, setTabsValue] = useState(0)

    const handleChange = (_: SyntheticEvent, value: number) => {
        setTabsValue(value)
    }

    const filteredCoupons =
        enabledPromotionDouble && refundType === RefundType.COUPON
            ? coupons.filter((coupon) =>
                  tabsValue === 1
                      ? coupon.couponType === PromotionType.DOUBLE
                      : coupon.couponType === PromotionType.SINGLE,
              )
            : coupons.filter((coupon) => coupon.couponType !== PromotionType.DOUBLE)

    return (
        <StyledContent>
            <StyledFirstRow>
                <StyledButton
                    onClick={onBackClick}
                    icon={<ArrowLeft color={colors.brown} />}
                    color={colors.transparent}
                    borderColor={colors.transparent}
                />
                <StyledH2 color={colors.brown}>{t(`complaints.listCouponModal.title.${refundType}`)}</StyledH2>
            </StyledFirstRow>

            {refundType === RefundType.COUPON && displayBanner && enabledPromotionDouble && (
                <BannerContainer>
                    <Banner
                        position={'relative'}
                        sx={{
                            backgroundColor: colors.orangeLight,
                            borderLeft: `3px solid ${colors.orange}`,
                            borderRadius: `0 ${BORDER.RADIUS.XL} ${BORDER.RADIUS.XL} 0`,
                        }}
                    >
                        <StyledBannerContentContainer>
                            <StyledBannerTextContainer>
                                <Text color={colors.brown} font={fonts.flame}>
                                    {t(`complaints.listCouponModal.banner.title`)}
                                </Text>
                                <StyledVerticalLine />
                                <StyledTextContainer>
                                    <Text font={fonts.flameSans} color={colors.brown}>
                                        {t(`complaints.listCouponModal.banner.description`)}
                                    </Text>
                                </StyledTextContainer>
                            </StyledBannerTextContainer>
                            <Cross size={12} color={colors.brown} onClick={handleClick} />
                        </StyledBannerContentContainer>
                    </Banner>
                </BannerContainer>
            )}

            {refundType === RefundType.COUPON && enabledPromotionDouble && (
                <StyledContainer>
                    <StyledTabsContainer>
                        <StyledTabs
                            value={tabsValue}
                            onChange={handleChange}
                            textColor="inherit"
                            variant="fullWidth"
                            aria-label="tabs coupons simples or doubles"
                            TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
                        >
                            <CustomTab
                                sx={{ textTransform: 'none', fontSize: '16px', fontFamily: fonts.flame }}
                                label="Coupons simples"
                                value={0}
                            />
                            <CustomTab
                                sx={{ textTransform: 'none', fontSize: '16px', fontFamily: fonts.flame }}
                                label="Coupons doubles"
                                value={1}
                            />
                        </StyledTabs>
                    </StyledTabsContainer>
                </StyledContainer>
            )}

            {filteredCoupons.length === 0 ? (
                <StyledLoaderContainer>
                    <ClipLoader size={70} color={colors.orange} loading={true} />
                </StyledLoaderContainer>
            ) : (
                <StyledCouponContainer>
                    {filteredCoupons.map((coupon) => (
                        <Coupon
                            key={coupon.promotionId}
                            nothingSelected={isSelectedPromotion === ''}
                            coupon={coupon}
                            selected={isSelectedPromotion === '' || coupon.promotionId === isSelectedPromotion}
                            onClick={() => selectPromotionClickHandler(coupon.promotionId || '')}
                            backgroundColor={
                                coupon.refundType === RefundType.CROWN ? colors.yellowMelty : colors.orange
                            }
                        />
                    ))}
                </StyledCouponContainer>
            )}

            <StyledButtonContainer>
                <Button
                    onClick={() => onConfirm(isSelectedPromotion)}
                    text={t('component.button.confirm')}
                    color={isSelectedPromotion === '' ? colors.greyLight : colors.red}
                    textColor={isSelectedPromotion === '' ? colors.blackLight : colors.white}
                    disabled={isSelectedPromotion === ''}
                />
            </StyledButtonContainer>
        </StyledContent>
    )
}

const StyledContent = styled.div`
    min-width: 45vw;
    box-sizing: border-box;
    max-width: 627px;
    padding: ${SPACING.XXL};
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const StyledH2 = styled(H2)`
    width: 100%;
    text-align: center;
`

const StyledCouponContainer = styled.div`
    display: grid;
    grid-column: 3;
    grid-template-columns: repeat(3, 177px);
    gap: ${SPACING.S};
    margin: auto;
    margin-bottom: ${SPACING.XXL};
`

const StyledLoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 516px;
    width: 100%;
`

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`

const StyledFirstRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${SPACING.XXXS};
    align-items: center;
    margin-bottom: ${SPACING.XXL};
`

const StyledButton = styled(Button)`
    border: none;
    padding: ${SPACING.NONE};
`

const StyledBannerContentContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`

const StyledBannerTextContainer = styled.div`
    display: flex;
    gap: ${SPACING.XS};
`

const StyledVerticalLine = styled.div`
    background-color: ${colors.brown};
    width: ${BORDER.WIDTH.XS};
    height: ${SPACING.M};
`

const StyledTextContainer = styled.div`
    display: flex;
    gap: ${SPACING.XXXS};
`

const BannerContainer = styled.div`
    padding-bottom: 25px;
`

const StyledTabsContainer = styled.div`
    width: 60%;
    background-color: ${colors.lightMayo};
    margin: auto;
    border-radius: 40px;
`

const StyledContainer = styled.div`
    width: 100%;
    padding-bottom: ${SPACING.XXL};
`

const CustomTab = styled(Tab)`
    &.MuiTab-fullWidth {
        color: ${colors.brownExtraLight};
        padding: ${SPACING.NONE};
    }

    &.Mui-selected {
        background-color: ${colors.white};
        border-radius: 40px;
        margin: 3px;
        color: ${colors.brown};
        padding: ${SPACING.NONE};
    }
`

const StyledTabs = styled(Tabs)`
    & .MuiTabs-indicator {
        height: ${SPACING.NONE};
    }
    text-transform: none;
`
