import { Avatar, CrownVIP, FlagIcon, Phone, ShopIcon } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { colors } from 'styles/colors'
import { H4 } from 'styles/typography'
import { ComplaintDTO } from 'types/api'
import { ComplaintStatus, ComplaintType } from 'types/api.enum'
import { SenderType } from 'utils/enums'

import { getComplaintGlobalStatus, getLastRestaurantMessageIndex } from '../../utils/util'
import {
    AttachmentsList,
    CaptionLine,
    ExchangeDiscussionComplaint,
    InfoStamp,
    InfoText,
    IssueAndSubIssueEditor,
    RefundInfoCard,
} from './components'
import { FraudCard } from './components/FraudCard'
import {
    StyledCardInfo,
    StyledInfoContainer,
    StyledLeftPane,
    StyledMainContainer,
    StyledMessageContainer,
    StyledText,
    StyledTypeContainer,
    StyledTypeIconContainer,
} from './style'

const ISSUES = {
    myOrder: 'complaints.issues.myOrder',
    myExperience: 'complaints.issues.myExperience',
}

const SUB_ISSUES = {
    myOrder: {
        missingProduct: 'complaints.subIssues.missingProduct',
        wrongProduct: 'complaints.subIssues.wrongProduct',
        orderQuality: 'complaints.subIssues.orderQuality',
        other: 'complaints.subIssues.other',
    },
    myExperience: {
        serviceAndDelay: 'complaints.subIssues.serviceAndDelay',
        myRestaurant: 'complaints.subIssues.myRestaurant',
        other: 'complaints.subIssues.other',
    },
}

type RestaurantComplaintDetailProps = {
    complaint: ComplaintDTO
    updateRequest: () => void
}

export const RestaurantComplaintDetail = ({ complaint, updateRequest }: RestaurantComplaintDetailProps) => {
    const { t } = useTranslation()

    const {
        status,
        createdDate,
        issue,
        subIssue,
        order,
        clientFirstName,
        clientLastName,
        clientPhone,
        description,
        messages,
        isReported,
        fraudLevel,
    } = complaint

    const toVerify = status === ComplaintStatus.WAITING_RESTAURANT
    const { orderNumber = `${t('complaints.emptyField')}`, clientVIP = false } = order || {}
    const clientFullname = `${clientFirstName} ${clientLastName || ''}`

    const { isRefunded, isAwaitingAction } = getComplaintGlobalStatus(status)

    return (
        <StyledMainContainer>
            <StyledLeftPane>
                <div>
                    <CaptionLine date={createdDate} />

                    <StyledMessageContainer>
                        <StyledText color={colors.brown}>{description || t('complaints.descriptionEmpty')}</StyledText>
                        <AttachmentsList attachments={complaint.attachments} complaintId={complaint.id} />
                    </StyledMessageContainer>

                    {messages &&
                        messages.map((messageObj, idx) => (
                            <StyledMessageContainer
                                key={`${idx}-${messageObj.message}`}
                                isRestaurant={messageObj.sender === SenderType.RESTAURANT}
                            >
                                {isRefunded && idx === getLastRestaurantMessageIndex(messages) && (
                                    <StyledCardInfo>
                                        <RefundInfoCard complaint={complaint} />
                                    </StyledCardInfo>
                                )}
                                <StyledText color={colors.brown}>{messageObj.message}</StyledText>
                            </StyledMessageContainer>
                        ))}

                    {isReported && <CaptionLine text={t(`complaints.isReported`)} />}
                </div>

                <ExchangeDiscussionComplaint complaint={complaint} canExchangeWithCustomer={isAwaitingAction} />
            </StyledLeftPane>
            <StyledInfoContainer>
                <StyledCardInfo>
                    <StyledTypeContainer>
                        <StyledTypeIconContainer>
                            <ShopIcon color={colors.brown} size={22} />
                        </StyledTypeIconContainer>
                        <H4 color={colors.brown}>{t(`complaints.complaintTypes.${ComplaintType.RESTAURANT}`)}</H4>
                    </StyledTypeContainer>
                </StyledCardInfo>
                {isRefunded && (
                    <StyledCardInfo>
                        <RefundInfoCard complaint={complaint} />
                    </StyledCardInfo>
                )}
                {isReported && (
                    <StyledCardInfo>
                        <StyledTypeContainer>
                            <StyledTypeIconContainer>
                                <FlagIcon />
                            </StyledTypeIconContainer>
                            <H4 color={colors.brown}>{t(`complaints.isReported`)}</H4>
                        </StyledTypeContainer>
                    </StyledCardInfo>
                )}
                <StyledCardInfo>
                    <IssueAndSubIssueEditor
                        complaintId={complaint.id ?? ''}
                        issueMap={ISSUES}
                        subIssueMap={SUB_ISSUES}
                        issue={issue || ''}
                        subIssue={subIssue || ''}
                        editable={toVerify}
                        updateRequest={updateRequest}
                    />
                    <InfoText title={t('complaints.orderNumber')} text={orderNumber} />
                </StyledCardInfo>
                <StyledCardInfo vip={clientVIP}>
                    <InfoStamp icon={<Avatar color={colors.brown} />} text={clientFullname} />
                    <InfoStamp icon={<Phone color={colors.brown} />} text={clientPhone || t('complaints.emptyField')} />
                    {clientVIP && (
                        <InfoStamp icon={<CrownVIP color={colors.orange} />} text={t('orders.vipId')} warning />
                    )}
                </StyledCardInfo>
                {fraudLevel && <FraudCard fraudScoring={fraudLevel} />}
            </StyledInfoContainer>
        </StyledMainContainer>
    )
}
