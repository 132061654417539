import { TextField } from '@mui/material'
import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { colors } from 'styles/colors'
import { fonts } from 'styles/fonts'
import { BORDER, SPACING } from 'styles/tokens'

type CombinedTextFieldProps = {
    parts: {
        message: string
        editable: boolean
        handleChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    }[]
    className?: string
}

export const CombinedTextField = ({ parts, className }: CombinedTextFieldProps) => {
    return (
        <StyledCombinedTextField className={className}>
            {parts.map((part, idx) => (
                <TextField
                    key={`${idx}-message`}
                    multiline
                    disabled={!part.editable}
                    fullWidth
                    InputProps={{
                        style: {
                            fontFamily: fonts.flameSans,
                            padding: '0',
                            margin: `${SPACING.XXXS} 0`,
                        },
                    }}
                    value={part.message}
                    onChange={(e) => part.handleChange && part.handleChange(e)}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: 'none',
                            },
                        },
                    }}
                />
            ))}
        </StyledCombinedTextField>
    )
}

const StyledCombinedTextField = styled.div`
    box-sizing: border-box;
    cursor: text;
    display: flex;
    align-items: center;
    border: 1px solid ${colors.greyLight};
    font-family: ${fonts.flameSans};
    border-radius: ${BORDER.RADIUS.XS};
    padding: ${SPACING.S};
    line-height: 22px;
    flex-direction: column;
    min-height: 200px;
`
